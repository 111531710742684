import { MDBRow, MDBCol } from "mdbreact";
import { SetStatusClasses, GetFixtureState } from "../../../common/helpers/status_helper";
import '../../../application/dashboards/css/fixture_dashboard.css';
import { Box, Chip, Paper, Tab, Tabs, Typography } from "@mui/material";
import { BiSync } from "react-icons/bi";
import { useFixtureSchedule } from "../../../application/fixtures/fixtureHook";
import { useState } from "react";
import '../dashboards/css/fixture_details.css';
import { styled } from "@mui/material";

const StyledTabs = styled((props) => (
    <Tabs variant="scrollable"
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  });

  const StyledTabsContainer = styled(Box)({
        
    });
  
  const StyledTab = styled((props) => <Tab {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'black', // Set default tab color to black
        '&.Mui-selected': {
            color: 'black', // Set selected tab color to black
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

export default function MobileFixtureDetails({ index, setIndex, activeStep, setActiveStep, formats, fixture_id, last_updated, isMobile, contentSetter, detailsResponse }) {
    const { fixture_display_information: fixtureInfo, league } = detailsResponse.data;

    const updatedDate = new Date(last_updated);
    const [schedule, SetSchedule] = useState({ isLoading: true, data: null });
    useFixtureSchedule(fixture_id, SetSchedule);

    const handleTabChange = (event, newValue) => {
        setIndex(newValue);
        setActiveStep(newValue);
    };

    const BuildCompetitionString = (information) => {
        let details = information.league.name;
        if (information.round != null) {
            details = `${details}, Round ${information.round.name}`;
        }
        return details;
    };

    return schedule.isLoading || (schedule.data == null && !schedule.isLoading) ? <></> : (
        <Paper elevation={1} className="mb-2 insight_fixture_summary widget_fade_in">
            <MDBRow className="widget_container pb-0">
                <div className="widget_heading" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className="league_logo" src={league.league_logo} alt={`${league.league_name} logo`} />
                        <span style={{ marginLeft: '3px', margin: 0, fontWeight: 500, lineHeight: 1.5, letterSpacing: '-0.3px', fontSize: '16px', fontWeight: 500 }}>
                            {BuildCompetitionString(schedule.data)}
                        </span>
                    </div>
                    <Chip
                        fontSize={12}
                        sx={{ position: 'relative', borderRadius: '10px' }}
                        label={`${String(updatedDate.getHours()).padStart(2, '0')}-${String(updatedDate.getMinutes()).padStart(2, '0')}-${String(updatedDate.getSeconds()).padStart(2, '0')}`}
                        size="small"
                        icon={<BiSync />}
                    />
                </div>

                <MDBRow style={{ borderRadius: '5px', marginTop: '7px' }}>
                    <MDBCol className="no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <img
                                style={{ height: '60px', width: '60px' }}
                                src={fixtureInfo.home_team_logo}
                                alt={fixtureInfo.home_team_name}
                            />
                        </div>
                    </MDBCol>
                    <MDBCol className="no_side_padding" style={{ textAlign: 'center' }}>
                        <Typography component="div" className={`${SetStatusClasses(fixtureInfo.fixture_state)} fixture_state`} style={{ margin: 'auto' }}>
                            <div className="text-level-2" style={{ fontSize: '29px', fontWeight: '700' }}>
                                {fixtureInfo.home_team_score} - {fixtureInfo.away_team_score}
                            </div>
                            <div className="text-level-2">
                                {GetFixtureState(fixtureInfo, true)}
                            </div>
                        </Typography>
                    </MDBCol>
                    <MDBCol className="no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div>
                            <img
                                style={{ height: '60px', width: '60px' }}
                                src={fixtureInfo.away_team_logo}
                                alt={fixtureInfo.away_team_name}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-2">
                    <MDBCol className="no_side_padding" style={{ textAlign: 'center' }}>
                        <span className="text-level-2">{fixtureInfo.home_team_name}</span>
                    </MDBCol>
                    <MDBCol className="no_side_padding">
                        {/* Placeholder for middle content if needed */}
                    </MDBCol>
                    <MDBCol className="no_side_padding" style={{ textAlign: 'center' }}>
                        <span className="text-level-2">{fixtureInfo.away_team_name}</span>
                    </MDBCol>
                </MDBRow>
                {formats.length > 0 &&
                <StyledTabsContainer sx={{ width: '100%' }}>
                    <StyledTabs
                        value={index}
                        onChange={handleTabChange}
                        aria-label="Fixture tabbed navigation"
                    >
                        {formats.map((item, idx) => (
                            <StyledTab style={{padding: '0px 5px 0px 5px'}} label={item} key={item + "_" + idx} />
                        ))}
                    </StyledTabs>
                </StyledTabsContainer>}
            </MDBRow>
        </Paper>
    );
}
