import React, { useState, useMemo } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import { Paper } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Typography } from '@mui/material';

const BuildStatisticProgress = ({ statistics, color }) => {
    return statistics.map((statistic) => (
        <div className='fixture_statistics_widget' key={`match_statistics_${statistic.id}`}>
            <MDBRow className='progress_row'>
                <MDBCol style={{ textAlign: 'left', fontSize: '16px', fontWeight: '600' }} size='4'>
                    {statistic.home_team_value}
                </MDBCol>
                <MDBCol className='no_side_padding' style={{ textAlign: 'center' }} size='4'>
                    <div style={{ fontSize: '14px', fontWeight: '500'}}>{statistic.name}</div>
                </MDBCol>
                <MDBCol style={{ textAlign: 'right', fontSize: '16px', fontWeight: '600' }} size='4'>
                    {statistic.away_team_value}
                </MDBCol>
            </MDBRow>
            <MDBRow className='progress_row'>
                <div className='progress_component'>
                    <div className='progress_bar'>
                        <div
                            className='line'
                            style={{ float: 'right', backgroundColor: color, width: `${statistic.home_percentage}%` }}
                        />
                    </div>
                    <div className='progress_bar'>
                        <div
                            className='line'
                            style={{ float: 'left', backgroundColor: color, width: `${statistic.away_percentage}%` }}
                        />
                    </div>
                </div>
            </MDBRow>
        </div>
    ));
};

const FixtureStatistics = ({ periods, fixture_statistics, statistic_types, isMobile }) => {
    const initialPeriod = useMemo(() => 
        periods.filter(({ sort_order }) => sort_order !== undefined)
               .sort((a, b) => a.sort_order - b.sort_order)[0]?.id
    , [periods]);

    const [periodID, setPeriod] = useState(initialPeriod);

    const handlePeriodChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setPeriod(newAlignment);
        }
    };

    const renderStatistics = (filterCondition, color) => (
        <BuildStatisticProgress
            statistics={fixture_statistics.filter(filterCondition)}
            color={color}
        />
    );

    return (
        <Paper elevation={1} className="mb-2 widget_fade_in p-2 pb-4 no_padding_left">
            <MDBRow>
                {isMobile ? <></> : <MDBCol className="pb-3" size='12'>
                    <label className="section_header no_background">Statistics</label>
                </MDBCol>}
                <MDBCol className="no_side_padding pb-4" size='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ToggleButtonGroup
                        className="fixture_period_toggle_group"
                        size='small'
                        value={periodID}
                        exclusive
                        onChange={handlePeriodChange}
                        aria-label="Period Selection"
                    >
                        {periods
                            .filter(period => period.description !== 'penalties')
                            .sort((a, b) => a.sort_order - b.sort_order)
                            .map((period) => (
                                <ToggleButton style={{width: (100 / periods.length) + '% !important'}} key={period.id} value={period.id} aria-label={period.short_name}>
                                    {period.short_name}
                                </ToggleButton>
                            ))}
                    </ToggleButtonGroup>
                </MDBCol>
                <MDBCol sm="12" className='no_side_padding'>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && a.name === 'Ball Possession %',
                        "#15b168"
                    )}
                </MDBCol>
                <MDBCol sm="12" className='no_side_padding pt-4'>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && a.stat_group === 'offensive',
                        "#c7361f"
                    )}
                </MDBCol>
                <MDBCol sm="12" className='no_side_padding'>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && a.stat_group === 'overall' && a.name !== 'Ball Possession %',
                        "#15b168"
                    )}
                </MDBCol>
                <MDBCol sm="12" className='no_side_padding pt-5'>
                    {renderStatistics(
                        a => (periodID === -1 ? true : a.period_id === periodID) && a.stat_group === 'defensive',
                        "#15b168"
                    )}
                </MDBCol>
            </MDBRow>
        </Paper>
    );
};

export default FixtureStatistics;
