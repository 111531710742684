import { useState } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import '../../../../application/dashboards/css/fixture_dashboard.css';
import { useFixtureDashboard, useFixtureSchedule, useLineups } from '../../../../application/fixtures/fixtureHook';
import FixturePenalties from '../../../../application/fixtures/fixture_penalties';
import FixtureStatistics from '../../../../application/fixtures/fixture_statistics';
import { AppBar, Box, Button, CircularProgress, Paper, styled, Tab, Tabs, Typography } from '@mui/material';
import { useLiveFixtureSubscription } from '../../../../application/infrastructure/subscriptions/fixtureSubscriptionHook';
import FixtureCommentary from '../../../../application/fixtures/fixture_commentary';
import SwipeableViews from 'react-swipeable-views';
import FixtureSchedule from '../../../../application/fixtures/fixture_schedule';
import LeagueTable from '../../../../application/standings/league_table';
import MobileFixtureDetails from '../../fixture/mobile_fixture_details';
import MobileFixtureLineups from '../../fixture/mobile_fixture_lineups';
import MobileFixtureEvents from '../../fixture/mobile_fixture_events';
import MobileFixturePitch from '../../fixture/mobile_fixture_pitch';
import MobileFixtureStatPack from '../../fixture/mobile_fixture_stat_pack';
import errorImg from '../../../../images/opps_something_went_wrong.jpg';
import { Link } from 'react-router-dom';
import FixtureHelmet from '../../../../application/helmets/fixture_helmet';

const FixtureDashboard = ({ fixture_id, contentSetter, isMobile, site_date }) => {
    const [fixtureDashboardResponse, setFixtureDashboard] = useState({
        isLoading: true,
        data: null,
        fixture_id,
        last_updated: null
    });

    const [schedule, setFixtureSchedule] = useState({ data: null, isLoading: true });

    const [activeStep, setActiveStep] = useState(0);
    const [index, setIndex] = useState(0);
    const [fixtureLineups, setFixtureLineups] = useState({ isLoading: true, data: null });

    useFixtureDashboard(fixture_id, setFixtureDashboard);
    useLiveFixtureSubscription(fixture_id, setFixtureDashboard);
    useLineups(fixture_id, setFixtureLineups);
    useFixtureSchedule(fixture_id, setFixtureSchedule);

    if(fixtureDashboardResponse.data === null && !fixtureDashboardResponse.isLoading) {
        return (
        <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <img src={errorImg} alt='no available fixture' style={{height: '280px', width: '265px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
                </MDBCol>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">An error occured</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">The fixture you where looking for could not be found</Typography>
                    <Link to={`/`}>
                        <Button className='mt-3' style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} onClick={() => contentSetter(1)} variant="contained" disableElevatio>Go Home</Button>
                    </Link>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if (!fixtureDashboardResponse.data ||
        fixtureDashboardResponse.isLoading ||
        fixture_id !== fixtureDashboardResponse.fixture_id) 
    {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </div>
        );
    }

    const 
    {
        data: 
        {
            events,
            periods,
            comments,
            fixture_display_information: displayInfo,
            period_statistics: periodStats,
            fixture_statistics: stats,
            last_updated
        },
    } = fixtureDashboardResponse;

    let teams = {
        home_team: { id: displayInfo.home_team_id, name: displayInfo.home_team_name, logo: displayInfo.home_team_logo }, 
        away_team: { id: displayInfo.away_team_id, name: displayInfo.away_team_name, logo: displayInfo.away_team_logo },  
    };

    const renderPenalties = (key) => {
        const penaltyPeriod = periods.find((period) => period.description === 'penalties');
        if (!penaltyPeriod) return null;

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <FixturePenalties
                    events={events}
                    period={penaltyPeriod}
                    home_team_logo={displayInfo.home_team_logo}
                    away_team_logo={displayInfo.away_team_logo}
                    home_team_id={displayInfo.home_team_id}
                    away_team_id={displayInfo.away_team_id}
                />
            </MDBCol>
        );
    };

    const renderCommentary = (key) => {
        if (!formats.includes('Commentary') || comments.length < 1) return null;
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <FixtureCommentary
                    periods={periods}
                    comments={comments}
                    isMobile={isMobile}
                    events={[]}
                    fixture_display_information={displayInfo}
                />
            </MDBCol>
        );
    };

    const renderStatistics = (key) => {
        if (!formats.includes('Statistics') || (periods.length < 1 && periodStats.length < 1)) return null;

        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <FixtureStatistics
                    periods={periods}
                    fixture_id={fixture_id}
                    fixture_statistics={stats}
                    isMobile={isMobile}
                />
            </MDBCol>
        );
    };

    const renderLineups = (key) => {
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                {
                    fixtureLineups.isLoading ? 
                    <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <CircularProgress sx={{ margin: 'auto' }} />
                    </Paper> :

                    <MobileFixturePitch 
                        fixture_id={fixture_id}
                        home_team={{ name: displayInfo.home_team_name, logo: displayInfo.home_team_logo}}
                        away_team={{ name: displayInfo.away_team_name, logo: displayInfo.away_team_logo}}
                        fixtureLineups={fixtureLineups}
                    />
                }

                {
                    fixtureLineups.isLoading ? 
                    <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                        <CircularProgress sx={{ margin: 'auto' }} />
                    </Paper> :

                    <MobileFixtureLineups
                        fixture_id={fixture_id}
                        last_updated={last_updated}
                        contentSetter={contentSetter}
                        isMobile={isMobile}
                        fixtureLineups={fixtureLineups}
                        teams={{ 
                            home_team: { name: displayInfo.home_team_name, logo: displayInfo.home_team_logo }, 
                            away_team: { name: displayInfo.away_team_name, logo: displayInfo.away_team_logo },  
                        }}
                    />
                }
            </MDBCol>
        );
    };
    
    const renderInfo = (key) => {
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <MDBRow>
                    {renderPenalties()}
                </MDBRow>
                {events.filter(event => event.section === "event").length > 0 && <MDBRow>
                    {renderEvents()}
                </MDBRow>}
                <MDBRow>
                    <FixtureSchedule fixture_id={fixture_id} teams={teams} schedule={schedule} />
                </MDBRow>
            </MDBCol>
        );
    };

    const renderEvents = (key) => {
        return (
            <MDBCol className="no_side_padding" size="12">
                <MobileFixtureEvents
                    events={events.filter(event => event.section === "event")}
                    periods={periods}
                    fixture_display_information={displayInfo}
                />
            </MDBCol>
        );
    };

    const renderLeagueStandings = (key) => {
        if (!formats.includes('Standings')) return null;
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <LeagueTable 
                    team_ids={[displayInfo.home_team_id, displayInfo.away_team_id]} 
                    season_id={displayInfo.season_id}
                    isMobile={isMobile}
                />
            </MDBCol>
        );
    };

    const renderStatPack = (key) => {
        if (!formats.includes('Stat Pack')) return null;
        return (
            <MDBCol key={key} className="no_side_padding" size="12">
                <MobileFixtureStatPack 
                    fixture_id={fixture_id}
                    teams={teams}
                />
            </MDBCol>
        );
    };

    var formats = ['Match'];

    if(fixtureLineups.data !== null && !fixtureLineups.isLoading) {
        formats.push('Lineups');
    }

    // if(comments.length > 0) {
        formats.push('Stat Pack');
    //}

    if(comments.length > 0) {
        formats.push('Commentary');
    }

    if(stats.length > 0) {
        formats.push('Statistics');
    }

    if(displayInfo.is_cup === false) {
        formats.push('Standings');
    }

    const renderTabItem = (item, index) => {
        let key = `${item}_${index}_tab`;
        switch(item)
        {
            case "Match":
                {
                    return renderInfo(key);
                }
            case "Commentary":
                {
                    return renderCommentary(key);
                }
            case "Lineups":
                {
                    return renderLineups(key);
                }
            case "Stat Pack":
                {
                    return renderStatPack(key);
                }
            case "Statistics":
                {
                    return renderStatistics(key);
                }
            case "Standings":
                {
                    
                    return renderLeagueStandings(key);
                }
            default:
                return null;
        }
    };

    return (
        <>
            <FixtureHelmet fixtureData={fixtureDashboardResponse.data} schedule={schedule} teams={teams} fixture_id={fixture_id} />
            <MDBRow>
                <MDBCol className="no_side_padding" size={isMobile ? "12" : "6"}>
                    <MDBRow>
                        <MDBCol className="no_side_padding" size="12">
                            <MobileFixtureDetails
                                isMobile={isMobile}
                                contentSetter={contentSetter}
                                fixture_id={fixture_id}
                                detailsResponse={fixtureDashboardResponse}
                                last_updated={last_updated}
                                formats={formats}
                                activeStep={activeStep}
                                setActiveStep={setActiveStep}
                                index={index}
                                setIndex={setIndex}
                            />
                        </MDBCol>
                    </MDBRow>
                    {
                        formats.length > 0 && 
                        <MDBRow>
                            <MDBCol className="no_side_padding">
                                <SwipeableViews index={index} onChangeIndex={setIndex}>
                                    {formats.map((item, idx) => renderTabItem(item, idx))}
                                </SwipeableViews>
                            </MDBCol>
                        </MDBRow>
                    }
                </MDBCol>
            </MDBRow>
        </>
    );
};

export default FixtureDashboard;
