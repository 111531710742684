import { Helmet } from 'react-helmet-async';

const BuildCompetitionString = (information) => {
    var details = information.league.name;
    if(information.stage != null) {
        details = `${details}, ${information.stage.name}`; 
    }
    if(information.round != null) {
        details = `${details}, Round ${information.round.name}`;
    }
    return details;
}

const BuildVenueDetails = (information) => {
    if(!information.venue)
    {
        return "";
    }

    var details = information.venue.name;
    if(information.venue.city != null) {
        details = `at ${details}, ${information.venue.city}`; 
    }
    return details;
}

const FixtureHelmet = ({fixtureData, schedule, teams, fixture_id}) => {
    if(schedule.isLoading || (schedule.data == null && !schedule.isLoading)) {
        return <></>
    }

    var competition_string = BuildCompetitionString(schedule.data);
    var fixture_information = 
    {
        title: `${competition_string} - ${teams.home_team.name} vs ${teams.away_team.name}`,
        description: `Live coverage of ${teams.home_team.name} vs ${teams.away_team.name} in ${competition_string} ${BuildVenueDetails(schedule.data)}. Get team lineups, live score, and in-game statistics.`,
        url: `https://www.thestatman.co.uk/fixture/${fixture_id}/${fixtureData.fixture_display_information.fixture_name}`,
        image: `${fixtureData.fixture_display_information.league_logo}`
    };

    return (
        <Helmet>
            {/* Standard Meta Tags */}
            <title>{fixture_information.title}</title>
            <meta name="description" content={fixture_information.description} />
            <link rel="canonical" href={fixture_information.url} />

            {/* Open Graph Meta Tags */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={fixture_information.title} />
            <meta property="og:description" content={fixture_information.description} />
            <meta property="og:url" content={fixture_information.url} />
            <meta property="og:image" content={fixture_information.image} />
            <meta property="og:site_name" content="The Stat Man" />
            <meta property="og:locale" content="en_UK" />

            {/* Twitter Card Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={fixture_information.title} />
            <meta name="twitter:description" content={fixture_information.description} />
            <meta name="twitter:image" content={fixture_information.image} />
            <meta name="twitter:site" content="@TheStatMan" /> {/* Replace with actual Twitter handle */}
            <meta name="twitter:creator" content="@TheStatMan" /> {/* Replace with actual Twitter handle */}

            {/* Additional Meta Tags */}
            <meta name="robots" content="index, follow" />
            <meta name="author" content="The Stat Man" />
            <meta name="keywords" content={`${teams.home_team.name}, ${teams.away_team.name}, ${fixtureData.league}, live scores, team stats, football match`} />

            {/* Favicon and Apple Touch Icon (Optional) */}
            <link rel="icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" href="/apple-touch-icon.png" sizes="180x180" />

            {/* Dublin Core Metadata (Optional for more context) */}
            <meta name="DC.title" content={fixture_information.title} />
            <meta name="DC.description" content={fixture_information.description} />
            <meta name="DC.language" content="en" />
        </Helmet>
    );
};

export default FixtureHelmet;