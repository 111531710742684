import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MDBCol } from "mdb-react-ui-kit";

export default function Goals({ statPack, team_id })
{
    return (
        <BuildGoalsTable 
            team_statistics_goals_dto={statPack.team_statistics_goals_dto}
            team_id={team_id}
        />
    )
}

const BuildGoalsTable = ({ team_statistics_goals_dto, team_id }) => (
    <MDBCol size="12" className="no_side_padding">
        <label className="mb-3" style={{textAlign: 'center', width: '100%', fontWeight: '500'}}>{team_statistics_goals_dto.type_name}</label>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{textAlign: 'center'}}>Total</TableCell>
                        <TableCell style={{textAlign: 'center'}}>Av.Game</TableCell>
                        <TableCell style={{textAlign: 'center'}}>% of Total</TableCell>
                        {/* <TableCell style={{textAlign: 'center'}}>First</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        team_statistics_goals_dto.values.find(stat => stat.team_id === team_id)?.goal_rows.map((stat, index) => {
                            return (<TableRow
                                key={"goal_results_" + index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                
                            >
                                <TableCell component="th" scope="row">
                                    {stat.name}
                                </TableCell>
                                <TableCell style={{textAlign: 'center'}}className={`stat-pack-value insight-${stat.count > 5 ? 'high' : stat.count}`}>{stat.count}</TableCell>
                                <TableCell style={{textAlign: 'center'}}>{stat.average ?? 0}</TableCell>
                                <TableCell style={{textAlign: 'center'}}>{stat.percentage ?? '-'}</TableCell>
                                {/* <TableCell style={{textAlign: 'center'}}>{stat.first ?? 0}</TableCell> */}
                            </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </MDBCol>
);