import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Row, Col, Container } from 'reactstrap';
import { Image } from 'react-bootstrap';

import bannerLogo from '../../images/stat_man_logo_aqua.jpg';
import { MobileDateController } from '../../application/shared/date/mobile_date_picker';
import { IoMdHome } from "react-icons/io";
import { Link } from 'react-router-dom';

function HideOnScroll(props) {

  const { children, window } = props;

  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({

    target: window ? window() : undefined,

  });

  return (

    <Slide appear={false} direction="down" in={!trigger}>

      {children}

    </Slide>

  );

}

HideOnScroll.propTypes = {

  children: PropTypes.element.isRequired,

  window: PropTypes.func,

};

const darkTheme = createTheme({

  palette: {

    mode: 'light',

    primary: {

      main: '#151e31',

    },

  },

});

function HideAppBar(props) {

  const { selected_date, set_date, setter } = props;

  return (

    <ThemeProvider theme={darkTheme}>

        <React.Fragment>

            <HideOnScroll {...props}>

                <AppBar color="primary" className="title-bar-container no-side-padding">

                    <Toolbar>

                        <Container className="title-bar-container no-side-padding">

                            <Row>

                                <Col style={{float: 'left', display: 'flex', alignItems: 'center' }}>

                                <Link to={'/'}>
                                  <IoMdHome color='white' size={35} onClick={() => setter(1)} style={{float: 'left', display: 'flex' }} />
                                </Link>
                                  
                                  {/* <MobileDateController selected_date={selected_date} set_date={set_date} /> */}

                                </Col>

                                <Col className="col-sm-9 col-md-9 col-lg-9 col-xl-9 no-side-padding">

                                  <Image src={bannerLogo} alt="The stat man banner image" className="banner-logo-top" style={{float: 'right'}}></Image>

                                </Col>

                            </Row>

                        </Container>

                    </Toolbar>

                </AppBar>

            </HideOnScroll>

        </React.Fragment>

    </ThemeProvider>

  );

}

export default connect()(HideAppBar);