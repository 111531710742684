import { MDBRow, MDBCol } from "mdbreact";
import { SetStatusClasses, GetFixtureState } from "../../common/helpers/status_helper";
import '../dashboards/css/fixture_dashboard.css';
import { Chip, Paper, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { BiSync } from "react-icons/bi";

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

export default function FixtureDetails({ fixture_id, last_updated, isMobile, contentSetter, detailsResponse }) {
    const { fixture_display_information: fixtureInfo, league } = detailsResponse.data;

    var updatedDate = new Date(last_updated);

    return (
        <Paper elevation={1} className="mb-2 insight_fixture_summary widget_fade_in">
            <MDBRow className="widget_container">
                <div className="widget_heading" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', textAlign: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img className="league_logo" src={league.league_logo} alt={`${league.league_name} logo`} />
                        <Typography variant="body1" style={{ marginLeft: '3px', margin: 0, fontWeight: 400, lineHeight: 1.5, letterSpacing: '-0.3px', fontSize: '16px' }}>{league.league_name}</Typography> {/* Add margin for spacing */}
                    </div>
                    <span> 
                        <BootstrapTooltip title={`Fixture last synced at ${String(updatedDate.getHours()).padStart(2, '0')}:${String(updatedDate.getMinutes()).padStart(2, '0')}:${String(updatedDate.getSeconds()).padStart(2, '0')}`}>
                            <Typography
                                variant="caption"
                                component="span"
                                noWrap
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block',
                                    maxWidth: '150px',
                                    fontWeight: 'bold',
                                    fontSize: '11px',
                                    color: '#9d9d9d'
                                }}>
                                <Chip sx={{fontSize: '12px'}} label={`${String(updatedDate.getHours()).padStart(2, '0')}:${String(updatedDate.getMinutes()).padStart(2, '0')}:${String(updatedDate.getSeconds()).padStart(2, '0')}`} size="small" icon={<BiSync />} />
                            </Typography> 
                        </BootstrapTooltip> 
                    </span>
                </div>
                <MDBRow className="team_summary" style={{padding: '10px 0px 0px 0px'}}>
                    <MDBCol className="team_logo_col no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* onClick={() => contentSetter(isMobile ? 6 : 1, fixtureInfo.home_team_id)} */}
                        <img
                            className="team_logo"
                            src={fixtureInfo.home_team_logo}
                            alt={fixtureInfo.home_team_name + " logo"}
                        />
                    </MDBCol>
                    <MDBCol className="column_insight_fixture_summary_score no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        <span
                            className={`${SetStatusClasses(fixtureInfo.fixture_state)} fixture_score event_status`}
                        >
                            {fixtureInfo.home_team_score} - {fixtureInfo.away_team_score}
                        </span>
                    </MDBCol>
                    <MDBCol className="team_logo_col no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* onClick={() => contentSetter(isMobile ? 6 : 1, fixtureInfo.away_team_id)} */}
                        <img
                            className="team_logo"
                            src={fixtureInfo.away_team_logo}
                            alt={fixtureInfo.away_team_name + " logo"}
                        />
                    </MDBCol>
                </MDBRow>

                <MDBRow className="team_names_row no_side_padding">
                    <MDBCol className="no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className="team_name" style={{ marginTop: '5px', width: '90%', textAlign: 'center' }}>{fixtureInfo.home_team_name}</span>
                    </MDBCol>
                    <MDBCol className="no_side_padding">
                        <label className={`${SetStatusClasses(fixtureInfo.fixture_state)} fixture_state`}>
                            {GetFixtureState(fixtureInfo, true)}
                        </label>
                    </MDBCol>
                    <MDBCol className="no_side_padding" style={{ display: 'flex', justifyContent: 'center' }}>
                        <span className="team_name" style={{ marginTop: '5px', width: '90%', textAlign: 'center' }}>{fixtureInfo.away_team_name}</span>
                    </MDBCol>
                </MDBRow>
            </MDBRow>
        </Paper>
    );
}
