import { Paper } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function FixturePenalties(props) {
    const { period, events, home_team_logo, away_team_logo, home_team_id, away_team_id } = props;

    // Filter and sort penalty events
    const penaltyEvents = events
        .filter(a => a.type_id === 22 || a.type_id === 23)
        .sort((a, b) => a.minute - b.minute);

    // Helper function to render penalty indicators
    const renderPenaltyIndicators = (events, team_id) => {
        const indicators = events.filter(a => a.team_id === team_id).map((event, index) => (
            <div key={index} className={event.event_type === 'pen_shootout_goal' ? 'penalty_scored' : 'penalty_missed'}></div>
        ));

        return (
            <>
                <div style={{ display: 'flex' }}>
                    {indicators.slice(0, 5)}
                </div>
                {indicators.length > 5 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: '16px' }}>
                        {indicators.slice(5)}
                    </div>
                )}
            </>
        );
    };

    // Helper function to render penalty scorers
    const renderPenaltyScorers = (events, team_id) => {
        const scorers = events.filter(a => a.team_id === team_id).map((event, index) => {
            const playerName = event.player_name ?? "";
            const isLast = index === events.length - 1;
            return (
                <div key={index} className={event.event_type === 'pen_shootout_goal' ? 'penalty_scorer_scored' : 'penalty_scorer_missed'}>
                    {playerName && (isLast ? playerName : `${playerName},`)}
                </div>
            );
        });

        return <div className="penalty_scorers">{scorers}</div>;
    };

    return (
        <Paper elevation={1} className="fixture_penalty_widget mb-2 widget_fade_in">
            <MDBRow className="pt-3 pb-3">
                <MDBCol className="no_side_padding" size="12">
                    <label className="section_header no_background">Penalties</label>
                </MDBCol>

                <MDBCol className="no_side_padding" size="12">
                    <div className="fixture_penalty_container" direction="column">
                        {[home_team_id, away_team_id].map((team_id, index) => {
                            const teamLogo = team_id === home_team_id ? home_team_logo : away_team_logo;
                            const teamPenalties = penaltyEvents.filter(a => a.team_id === team_id);
                            const totalScored = teamPenalties.filter(a => a.event_type === 'pen_shootout_goal').length;

                            return (
                                <div key={index} className="penalty_outer_container pb-3">
                                    <div className="team_penalties">
                                        <div className="logo_container">
                                            <img className="team_logo" title="team logo" src={teamLogo} alt="Team Logo" />
                                        </div>
                                        <div className="penalty_outcomes">
                                            {renderPenaltyIndicators(penaltyEvents, team_id)}
                                        </div>
                                        <div className="penalty_total_container">
                                            <div className="total_label">{totalScored}</div>
                                        </div>
                                    </div>
                                    {renderPenaltyScorers(penaltyEvents, team_id)}
                                </div>
                            );
                        })}
                    </div>
                </MDBCol>
            </MDBRow>
        </Paper>
    );
}
