import React, { useCallback, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import ErrorBoundary from "../error/error_boundary";
import { IoIosFootball } from "react-icons/io";
import { FaAmbulance, FaArrowCircleRight, FaFlagCheckered, FaPlus, FaTv } from "react-icons/fa";
import { IoMdAlarm } from "react-icons/io";
import { GiReturnArrow } from "react-icons/gi";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { BiFootball, BiTargetLock } from "react-icons/bi";
import { LuFlagTriangleRight } from "react-icons/lu";
import { IoFootballOutline } from "react-icons/io5";
import { HiMiniArrowPathRoundedSquare } from "react-icons/hi2";
import { Typography } from "@mui/material";
import { FaArrowCircleLeft } from "react-icons/fa";

function BuildEvent({ event }) {
    const { event_type, sub_type_id, is_home, minute, extra_minute, player_name, assisting_player_name, description, type_name, result, additional_information } = event;

    const getEventDetails = () => {
        switch (event_type) {
            case "substitution":
                return {
                    icon: sub_type_id !== 1524 ? <HiMiniArrowPathRoundedSquare fill="#374df5" size={25} /> : <FaAmbulance fill="#dd3c23" size={25} />,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">
                                {
                                    is_home ?
                                    <>
                                        <FaArrowCircleRight style={{position: 'relative', top: '-2px', marginRight: '5px'}} size={15} color="green" /> 
                                        <span className="sub_in">{player_name}</span>
                                    </> : 
                                    <>
                                        <span className="sub_in">{player_name}</span> 
                                        <FaArrowCircleRight style={{position: 'relative', top: '-2px', marginLeft: '5px'}} size={15} color="green" /> 
                                    </>
                                }
                            </Typography>
                            <Typography variant="body2" className="description">
                                {
                                    is_home ?
                                    <>
                                        <FaArrowCircleLeft style={{position: 'relative', top: '-2px', marginRight: '5px'}} size={15} color="red" /> 
                                        <span className="sub_out" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{assisting_player_name}</span>
                                    </> :
                                    <>
                                        <span className="sub_out" style={{ whiteSpace: 'nowrap', overflow: 'hidden', marginLRight: '3px' }}>{assisting_player_name}</span> 
                                        <FaArrowCircleLeft style={{position: 'relative', top: '-2px', marginLeft: '5px'}} size={15} color="red" /> 
                                    </>
                                }
                            </Typography>
                        </div>
                    )
                };
            case "VAR_CARD":
                {
                    return {
                        icon: <FaTv size={25} />,
                        details: (
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                                <Typography variant="body1" className="player-name">{player_name}</Typography>
                                <Typography variant="caption" className="description">{additional_information === "" || additional_information === null ? <></> : `Reason: ${additional_information}`}</Typography>
                            </div>
                        )
                    };
                }
            case "yellowredcard":
            case "redcard":
                return {
                    icon: <div className="red_card"></div>,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">{player_name == null ? <></> : player_name}</Typography>
                            <Typography variant="caption" className="description">{description === "" || description === null ? <></> : `Reason: ${description}`}</Typography>
                        </div>
                    )
                };
            case "yellowcard":
                return {
                    icon: <div className="yellow_card"></div>,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">{player_name == null || player_name == "" ? "Yellow Card" : player_name}</Typography>
                            <Typography variant="caption" className="description">{description === "" || description === null ? <></> : `Reason: ${description}`}</Typography>
                        </div>
                    )
                };
            case "penalty":
                return {
                    icon: <BiTargetLock color="green" size={25} />,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">{is_home ? 
                                    <><span style={{fontWeight: '500', marginRight: '5px'}}>{result}</span><span>{player_name == null ? "Penalty" : player_name}</span></> : 
                                    <><span>{player_name == null ? "Penalty" : player_name}</span><span style={{fontWeight: '500', marginLeft: '5px'}}>{result}</span></>
                                }</Typography>
                            <Typography variant="body2" className="description">{type_name}</Typography>
                        </div>
                    )
                };
            case "owngoal":
                return {
                    icon: <IoIosFootball fill="red" size={25} />,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">
                                {is_home ? 
                                    <><span style={{fontWeight: '500', marginRight: '5px'}}>{result}</span><span>{player_name == null ? "Own Goal" : player_name}</span></> : 
                                    <><span>{player_name == null ? "Own Goal" : player_name}</span><span style={{fontWeight: '500', marginLeft: '5px'}}>{result}</span></>
                                }
                            </Typography>
                            <Typography variant="body2" className="description bold_description">{assisting_player_name}</Typography>
                        </div>
                    )
                };
            case "goal":
                return {
                    icon: <IoFootballOutline color="green" size={25} />,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">
                            {is_home ? 
                                <><span style={{fontWeight: '500', marginRight: '5px'}}>{result}</span><span>{player_name == null ? "Goal" : player_name}</span></> : 
                                <><span>{player_name == null ? "Goal" : player_name}</span><span style={{fontWeight: '500', marginLeft: '5px'}}>{result}</span></>
                            }
                            </Typography>
                            {
                                assisting_player_name != null ?
                                <Typography variant="caption" className="description">Assist: <span style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{assisting_player_name}</span></Typography> :
                                <></>
                            }
                        </div>
                    )
                };
            case "VAR":
                return {
                    icon: <FaTv color="black" size={25} />,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">{additional_information}</Typography>
                            <Typography variant="caption" className="description">{player_name}</Typography>
                        </div>
                    )
                };
            case "missed_penalty":
                return {
                    icon: <GiReturnArrow color="red" size={25} />,
                    details: (
                        <div style={{ display: 'flex', flexDirection: 'column', textAlign: is_home ? 'left' : 'right' }}>
                            <Typography variant="body1" className="player-name">{additional_information}</Typography>
                            <Typography variant="body2" className="description">{player_name}</Typography>
                        </div>
                    )
                };
            case "shot-off-target":
                {
                    return {
                        icon: <BiFootball color="red" size={20} />,
                        details: (
                            <>
                                <span className="player-name">{additional_information}</span>
                                <span className="description">{player_name}</span>
                            </>
                        )
                    };
                };
            case "shot-on-target":
                {
                    return {
                        icon: <BiFootball color="green" size={20} />,
                        details: (
                            <>
                                <span className="player-name">{additional_information}</span>
                                <span className="description">{player_name}</span>
                            </>
                        )
                    };
                };
            case "offside":
                {
                    return {
                        icon: <FaFlagCheckered color="black" size={20} />,
                        details: (
                            <>
                                <span className="player-name">{additional_information}</span>
                                <span className="description">{player_name}</span>
                            </>
                        )
                    };
                };
            case "corner":
                {
                    return {
                        icon: <LuFlagTriangleRight color="black" size={20} />,
                        details: (
                            <>
                                <span className="player-name">{additional_information}</span>
                                <span className="description">{player_name}</span>
                            </>
                        )
                    };
                };
            default:
                return {};
        }
    };

    const { icon, details } = getEventDetails();

    return (
        <div className={`fixture-event ${is_home ? '' : 'reverse'} no_side_padding`}>
            <div className="icon">{icon}</div>
            <div className={`info ${is_home ? '' : 'reverse'}`}>
                <Typography variant="body1" className={`minute-container ${is_home ? '' : 'reverse'}`}>
                    <span className="minute">
                        {minute}'{extra_minute !== null ? <sup className="extra-minute">{extra_minute}</sup> : null}
                    </span>
                </Typography>
                <div className="separator"></div>
                {details}
            </div>
        </div>
    );
}

function BuildEvents({ events, period }) {
    if (events.length < 1) return null;

    return events
        .map(event => (
            <MDBRow key={`fixture_event_${event.id}`} className="no_side_padding">
                <MDBCol className="pt-2 pb-2">
                    <BuildEvent event={event} />
                </MDBCol>
            </MDBRow>
        ));
}

function GetAddedMinutesDisplay({ period }) {
    return period.time_added == null ? null : (
        <Typography variant="caption" className="additional_minutes">
            <IoMdAlarm size={15} />{`${period.time_added} Additional Minutes`}
        </Typography>
    );
}

function GetSeparatorText({ is_current_period, period, fixture_display_information }) {
    if (is_current_period && fixture_display_information.fixture_state === "FT") {
        return `Full Time ${fixture_display_information.home_team_score}-${fixture_display_information.away_team_score}`;
    }

    if (is_current_period) {
        return `${period.type_name} ${fixture_display_information.home_team_score}-${fixture_display_information.away_team_score}`;
    }

    switch (period.description) {
        case "1st-half":
            return `${period.type_name} ${fixture_display_information.first_half_home_score}-${fixture_display_information.first_half_away_score}`;
        case "2nd-half":
            return `${period.type_name} ${fixture_display_information.second_half_home_score}-${fixture_display_information.second_half_away_score}`;
        default:
            return `${period.type_name} ${fixture_display_information.home_team_score}-${fixture_display_information.away_team_score}`;
    }
}

export default function FixtureEvents({ events, fixture_display_information, periods }) {

    const [periodID, setPeriodID] = useState(-1);

    const handlePeriodChange = useCallback((event, newPeriodID) => {
        setPeriodID(newPeriodID);
    }, []);

    return (
      <ErrorBoundary>
        <MDBCol size='6' style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
            <ToggleButtonGroup
                className="fixture_period_toggle_group"
                size='small'
                value={periodID}
                exclusive
                onChange={handlePeriodChange}
                aria-label="text alignment"
            >
                <ToggleButton color="primary" key="event_toggle_group_all" value={-1} aria-label="left aligned">All</ToggleButton>
                {periods.sort((a, b) => a.sort_order - b.sort_order)
                    .filter(a => a.description !== 'penalties')
                    .map(period => (
                        <ToggleButton color="primary" key={period.id} value={period.id} aria-label="left aligned">
                            {period.short_name}
                        </ToggleButton>
                    ))}
            </ToggleButtonGroup>
        </MDBCol>
        {periods.sort((a, b) => b.sort_order - a.sort_order)
            .filter(a => (periodID === -1 ? true : a.id === periodID) && a.description !== 'penalties')
            .map((period, index) => (
                <React.Fragment key={`period_events_${period.id}`}>
                    <div className="event_period_separator">
                        <label>{GetSeparatorText({ is_current_period: (index === 0), period, fixture_display_information })}</label>
                        {GetAddedMinutesDisplay({ period })}
                    </div>
                    {BuildEvents({ events: events.filter(a => a.period_id === period.id).sort((a, b) => (b.minute + (b.extra_minute == null ? 0 : b.extra_minute)) - (a.minute + (a.extra_minute == null ? 0 : a.extra_minute))), period })}
                </React.Fragment>
            ))
        }
        <React.Fragment key={`period_events_on_bench`}>
            <div className="event_period_separator">
                <label>Bench</label>
            </div>
            {BuildEvents({ events: events.filter(a => a.is_bench === true).sort((a, b) => (b.minute + (b.extra_minute == null ? 0 : b.extra_minute)) - (a.minute + (a.extra_minute == null ? 0 : a.extra_minute))) })}
        </React.Fragment>
      </ErrorBoundary>
  );
}