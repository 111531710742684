export function GetFixtureState(result, fullName) {

    switch(result.fixture_state) {

        case "NS":
            {

                return result.fixture_time;

            }
        case "ET":
            {

                return result.fixture_minutes === null ? "-" : (result.fixture_minutes + (result.extra_minutes !== null ? result.extra_minutes : 0)) + "'";

            }
        case "INPLAY_PENALTIES":
            {

                return fullName ? "Penalties" : "PEN"

            }
        case "FT_PEN":
            {

                return fullName ? "After Penalties" : "FT PEN"

            }
        case "INPLAY_1ST_HALF":
        case "INPLAY_2ND_HALF":
        case "INPLAY_ET":
        case "INPLAY_ET_2ND_HALF":
            {
                var extra_minutes = "";
                switch(result.fixture_state)
                {
                    case "INPLAY_1ST_HALF":
                        {
                            extra_minutes = 
                                result.first_half_extra_minutes === "" ||
                                result.first_half_extra_minutes === null ? "" : result.first_half_extra_minutes;
                        }
                    case "INPLAY_2ND_HALF":
                        {
                            extra_minutes = 
                                result.second_half_extra_minutes === "" ||
                                result.second_half_extra_minutes === null ? "" : result.second_half_extra_minutes;
                        }
                }
                
                return result.fixture_minutes === null ? 
                    "-" : 
                    `${result.fixture_minutes}'`;  
            }
        case "BREAK":
            {

                return "BREAK";

            }
        case "POSTPONED":
            {
                return fullName ? "Postponed" : "POST";
            }
        
        case "AET":
        case "FT":
        case "HT":
            {

                if(fullName) 
                {

                    switch(result.fixture_state) 
                    {

                        default:
                            {

                                return "";

                            }
                        case "FT":
                            {

                                return "Full Time";

                            }
                        case "HT":
                            {

                                return "Half Time";

                            }
                        case "ET":
                            {

                                return "Extra Time";

                            }
                        case "AET":
                            {

                                return "After Extra Time";

                            }

                    }

                }   
                else 
                {

                    return result.fixture_state;

                }

            }
            default:
            {

                return "";

                // return !IsInPast(result.fixture_Date) ? '' : fixture_and_Result.status;

            }

    }

}

export function SetStatusClasses(status) {
        
    let status_classes = {};

    switch(status)
    {

        case "POSTPONED":
            {

                return 'status_postponed';

            }
        case "INPLAY_1ST_HALF":
        case "INPLAY_2ND_HALF":
        case "INPLAY_ET":
        case "INPLAY_PENALTIES":
        case "INPLAY_ET_2ND_HALF":
            {

                return 'status_live';

            }
        case "HT":
            {

                return 'status_live';

            }
        case "ET":
            {

                return 'status_live';

            }
        case "NS":
            {

                return '';

            }
        case "FT":
            {

                return '';

            }
        case "AET":
            {

                return '';

            }
        default:
            {

                return "";

            }

    }

}