import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { CircularProgress, Divider, LinearProgress, linearProgressClasses, Paper, Typography } from '@mui/material';
import '../../dashboards/css/lineup_statistic_drawer.css';
import { useEffect, useRef, useState } from 'react';
import { useIsVisible } from '../../../../application/shared/hooks/isVisibleHook';
import { Chip } from '@mui/material';
import { BiCheck, BiFootball, BiTargetLock } from 'react-icons/bi';
import { GiBootKick, GiWhistle } from 'react-icons/gi';
import { Gauge } from '@mui/x-charts/Gauge';
import { GetGradientClass } from '../../../../common/helpers/rating_helper';

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: grey[100],
  ...theme.applyStyles('dark', {
    backgroundColor: theme.palette.background.default,
  }),
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[800],
  }),
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'relative',
  top: 8,
  left: 'calc(50% - 15px)',
  ...theme.applyStyles('dark', {
    backgroundColor: grey[900],
  }),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '12px 0',
  borderColor: grey[400],
  height: '2px',
  borderStyle: 'solid',
}));

const PlayerStatisticDrawer = ({ drawerState, setUpdatedDrawerState }) => {
  const [loading, setLoading] = useState(false);
  const componentMounted = useRef(true);
  const [isVisible, setIsVisible] = useState(false);
  useIsVisible(isVisible, setIsVisible, componentMounted);

  useEffect(() => {
    if (drawerState.open) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [drawerState.open]);

  if (!drawerState.player) {
    return null;
  }

  const player = drawerState.player;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'calc(100vh - 56px)',
            overflow: 'auto',
          },
        }}
      />
      <SwipeableDrawer
          anchor="bottom"
          open={drawerState.open}
          onClose={() => setUpdatedDrawerState(false, null)}
          onOpen={() => setUpdatedDrawerState(true, player)}
          swipeAreaWidth={100}
          disableBackdropTransition={true}
          disableSwipeToOpen={false}
          ModalProps={{ keepMounted: false }}
          sx={{
            '& .MuiDrawer-paper': {
              overflow: 'auto',
              height: 'calc(100vh - 56px)',
            },
          }}
      >
        <StyledBox
          sx={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
        </StyledBox>
        <MDBRow style={{ padding: '20px 0px 10px 0px', borderBottom: '1px solid #cfcfcf' }}>
          <MDBCol className='player-container'>
            <img style={{marginRight: '10px'}} className="drawer-player-image" src={player.playerImage} alt={`Image of ${player.name}`} />
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <label className="player-name">{player.name}</label>
              <div style={{position: 'relative'}}>
                <img className="drawer-team-logo" src={player.team.logo} alt={`${player.team.name} Logo`} />
                <span style={{fontWeight: '400', fontSize: '18px' }}>
                  {player.team.name}
                </span>
              </div>
            </div>
            <span style={{}} className={GetGradientClass(player.rating) + " player-rating"}>{player.rating}</span>
          </MDBCol>
          <MDBCol style={{ paddingTop: '10px' }} size='12'>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {player.statistics.map(stat => {
                  if (stat.type_name === "Goals" && stat.value > 0) {
                    return <Chip key={stat.id} variant="outlined" size="small" style={{ marginRight: '5px', minWidth: '72px' }} icon={<BiFootball size={16} />} label="Goal" color="success" />;
                  }
                  if (stat.type_name === "Assists" && stat.value > 0) {
                    return <Chip key={stat.id} variant="outlined" size="small" style={{ marginRight: '5px', minWidth: '72px' }} icon={<GiBootKick size={16} />} label="Assist" color="success" />;
                  }
                  if (stat.type_name === "Fouls" && stat.value > 0) {
                    return <Chip key={stat.id} variant="outlined" size="small" style={{ marginRight: '5px', minWidth: '72px' }} icon={<GiWhistle size={16} />} label="Foul" color="error" />;
                  }
                  if (["Fouls Drawn"].includes(stat.type_name) && stat.value > 0) {
                    return <Chip key={stat.id} variant="outlined" size="small" style={{ marginRight: '5px', minWidth: '72px' }} icon={<GiWhistle size={16} />} label="Fouled" color="success" />;
                  }
                  if (["Yellowcards", "Yellowred Cards", "Redcards"].includes(stat.type_name) && stat.value > 0) {
                    return <Chip key={stat.id} variant="outlined" size="small" style={{ marginRight: '5px', minWidth: '72px' }} icon={<BiCheck size={16} />} label="Carded" color="error" />;
                  }
                  return null;
                })}
              </div>
          </MDBCol>
        </MDBRow>
        <MDBRow style={{height: '100%'}} className={` ${isVisible ? 'fade-in' : ''}`}>
          <MDBCol className="no_side_padding" sm="12" md="12" lg="12" xl="12">
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </div>
            ) : (
              renderStatisticsTables(player)
            )}
          </MDBCol>
        </MDBRow>
      </SwipeableDrawer>
    </Root>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));

function PassStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px 10px 10px 10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Passes</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Accurate Passes</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.pass_statistics.accurate_passes || 0}/{player.statistics_wrapper.pass_statistics.total_passes || 0}
              ({player.statistics_wrapper.pass_statistics.accurate_passes_percentage}%)
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Key Passes</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.pass_statistics.key_passes || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Long Balls</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.pass_statistics.long_balls || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{  display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Long Balls Won</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.pass_statistics.long_balls_won || 0}</span>
        </div>
      </div>
    </>
  );
}

function ShotStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px 10px 10px 10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Shots</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Total Shots</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.shot_statistics.total_shots}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Shots On Target</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.shot_statistics.shots_on_target || 0}/{player.statistics_wrapper.shot_statistics.total_shots || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Shots Off Target</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{(player.statistics_wrapper.shot_statistics.shots_off_target || 0) + (player.statistics_wrapper.shot_statistics.shots_blocked || 0)}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Shots Blocked</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.shot_statistics.shots_blocked || 0}</span>
        </div>
      </div>
    </>
  );
}

function OverallStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px 10px 10px 10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Overall</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Minutes Played</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.minutes_played || 0}'</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Touches</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.overall_statistics.touches || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Fouls</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.overall_statistics.fouls || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Fouls Drawn</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>
              {player.statistics_wrapper.overall_statistics.fouled || 0}
          </span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Yellow Card</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.yellow_card || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Yellow / Red Card</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.yellow_red_card || 0}</span>
        </div>
        <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Red Card</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.overall_statistics.red_card || 0}</span>
        </div>
      </div>
    </>
  );
}

function DuelStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Duels</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Total Duels</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.duel_statistics.total_duels || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Duels Won</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.duel_statistics.duels_won || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Duels Lost</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.duel_statistics.duels_lost || 0}</span>
        </div>
      </div>
    </>
  );
}

function GoalKeepingStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>Goalkeeping</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Saves</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.saves || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Claims</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.come_outs || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Punches</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.punches || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Saves Inside Box</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.saves_inside_box || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Goals Conceded</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.goalkeeping_statistics.goalkeeper_goals_conceded || 0}</span>
        </div>
      </div>
    </>
  );
}

function GeneralPlayStatistics({ player }) {
  return (
    <>
      <label style={{ padding: '10px', backgroundColor: '#F5F6FF', width: '100%', textAlign: 'left', fontSize: '16px', fontWeight: '700' }}>General Play</label>
      <div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Dribble Attempts</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.dribble_attempts || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Successful Dribbles</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.Successful_Dribbles || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Dribbled Past</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.dribbled_past || 0}</span>
        </div>
        <div style={{ borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>
        <div className='stat-group-statistic' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span className='no_side_padding statistic-name text-level-2'>Dispossessed</span>
          <span className='no_side_padding statistic-value' style={{ textAlign: 'center' }}>{player.statistics_wrapper.general_play_statistics.disposessed || 0}</span>
        </div>
      </div>
    </>
  );
}

const renderStatisticsTables = (player) => {
  return (
    <MDBRow className="drawer-statistics-table pb-3">
      <MDBCol className='stat-table-container no_side_padding' style={{ display: 'flex', flexDirection: 'column' }}>
        {player.statistics_wrapper.overall_statistics && <OverallStatistics player={player} />}
        {player.statistics_wrapper.goalkeeping_statistics && <GoalKeepingStatistics player={player} />}
        {player.statistics_wrapper.general_play_statistics && <GeneralPlayStatistics player={player} />}
        {player.statistics_wrapper.pass_statistics && <PassStatistics player={player} />}
        {player.statistics_wrapper.shot_statistics && <ShotStatistics player={player} />}
        {player.statistics_wrapper.duel_statistics && <DuelStatistics player={player} />}
      </MDBCol>
    </MDBRow>
  );
}

PlayerStatisticDrawer.propTypes = {
  drawerState: PropTypes.shape({
    player: PropTypes.object,
    open: PropTypes.bool.isRequired,
  }).isRequired,
  setUpdatedDrawerState: PropTypes.func.isRequired,
};

export default PlayerStatisticDrawer;
