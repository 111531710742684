import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { league_selection_item } from '../application/dashboards/dashboard_system/interface_store';
import configuration from '../config.json';
import { iselected_mobile_dashboard_type, iselected_secondary_dashboard_type, iselected_content_dashboard_type, primary_dashboard_menu } from '../application/dashboards/dashboard_system/dashboard_interfaces';
import { SiteDateSwitchAction, MobileDashboardSwitchAction, PrimaryDashboardSwitchAction, SecondaryDashboardSwitchAction, ContentDashboardSwitchAction } from '../application/dashboards/dashboard_system/dashboard_navigation_system';

// ---------------------
// ----DEFAULT STATE----
// ---------------------

export interface LeagueSelectionState {

    isLoading: boolean,

    league_selection_response: league_selection_item[],

    error: string;

}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// -----------------

// -----------------------
// ----LEAGUE PROFILES----
// -----------------------

interface RequestLeagueSelect {

    type: 'REQUEST_LEAGUE_SELECT';

}

interface ReceiveLeagueSelect {

    type: 'RECEIVE_LEAGUE_SELECT';

    league_selection_response: league_selection_item[];

    isLoading: boolean;

    error: string;

}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type LeagueSelectKnownAction = RequestLeagueSelect | ReceiveLeagueSelect;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {

    switchSiteDate: (date: string | null): AppThunkAction<SiteDateSwitchAction> => (dispatch, getState) => {

        const appState = getState();

        if(appState.site_date !== date) 
        {

            dispatch({

                type: 'RECEIVE_SITE_DATE',
            
                site_date: date
            
            });    

        } 
        else {

            dispatch({

                type: 'REQUEST_SITE_DATE'
            
            }); 

        }
             
    },

    switchPrimaryDashboard: (selectedLeagueID: number | null): AppThunkAction<PrimaryDashboardSwitchAction> => (dispatch, getState) => {

        const appState = getState();

        if
        (
            
            appState.primary_dashboard_state.selectedLeagueID !== selectedLeagueID

        ) 
        {

            dispatch({ 
            
                type: 'RECIEVE_PRIMARY_DASHBOARD',
                
                selectedLeagueID: selectedLeagueID,

                menu_items: primary_dashboard_menu.menu_items
    
            });    

        }
        else
        {

            dispatch({ 
            
                type: 'REQUEST_PRIMARY_DASHBOARD'
                
            });   

        }
             
    },

    switchSecondaryDashboard: (selected_dashboard_type: iselected_secondary_dashboard_type): AppThunkAction<SecondaryDashboardSwitchAction> => (dispatch, getState) => {

        const appState = getState();

        if
        (
            
            appState.secondary_dashboard_state.selectedDashboardType !== selected_dashboard_type

        ) 
        {

            dispatch({ 
            
                type: 'RECEIVE_SELECTED_SECONDARY_DASHBOARD',
                
                selectedDashboardType: selected_dashboard_type,

                selectedLeagueID: appState.primary_dashboard_state.selectedLeagueID
    
            });    

        }
        else
        {

            dispatch({ 
            
                type: 'REQUEST_SELECTED_SECONDARY_DASHBOARD'
                
            });   

        }
             
    },

    switchContentDashboard: (selected_dashboard_type: iselected_content_dashboard_type, selectedContentID: number | null): AppThunkAction<ContentDashboardSwitchAction> => (dispatch, getState) => {

        const appState = getState();

        if(appState.content_dashboard_state.selectedContentID === selectedContentID && selectedContentID !== null) {

            return appState.content_dashboard_state;

        }

        if
        (
            
            appState.content_dashboard_state.selectedDashboardType !== selected_dashboard_type || 
            
            appState.content_dashboard_state.selectedContentID !== selectedContentID
            
        ) 
        {

            dispatch({ 
            
                type: 'RECEIVE_SELECTED_CONTENT_DASHBOARD', 
                
                selectedDashboardType: selected_dashboard_type,
    
                selectedContentID: selectedContentID
    
            });    

        }
        else
        {

            dispatch({ 
            
                type: 'REQUEST_SELECTED_CONTENT_DASHBOARD'
                
            });   

        }
             
    },

    switchMobileDashboard: (selected_dashboard_type: iselected_mobile_dashboard_type, selectedContentID: number | null): AppThunkAction<MobileDashboardSwitchAction> => (dispatch, getState) => {

        const appState = getState();

        if
        (
            
            appState.mobile_dashboard_state.selectedDashboardType !== selected_dashboard_type || 
            
            appState.mobile_dashboard_state.selectedContentID !== selectedContentID
            
        ) 
        {

            dispatch({ 
            
                type: 'RECEIVE_SELECTED_MOBILE_DASHBOARD', 
                
                selectedDashboardType: selected_dashboard_type,
    
                selectedContentID: selectedContentID
    
            });    

        }
        else
        {

            dispatch({ 
            
                type: 'REQUEST_SELECTED_MOBILE_DASHBOARD'
                
            });   

        }
             
    },

    requestLeagueSelections: (): AppThunkAction<LeagueSelectKnownAction> => (dispatch, getState) => {

        const appState = getState();

        if((appState.leagueSelectionState === undefined || appState.leagueSelectionState.league_selection_response === undefined || appState.leagueSelectionState.league_selection_response.length < 1)) {
            fetch(`${configuration.FRONT_END_API_v2}Leagues`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.json();
            })
            .then(responseJson => {
                dispatch({
                    type: 'RECEIVE_LEAGUE_SELECT',
                    league_selection_response: responseJson,
                    isLoading: false,
                    error: ""
                });
            })
            .catch(error => {
                console.error("Fetch error:", error);
                dispatch({
                    type: 'RECEIVE_LEAGUE_SELECT',
                    league_selection_response: [],
                    isLoading: false,
                    error: error.message
                });
            });

        }
        else 
        {

            dispatch({ type: 'REQUEST_LEAGUE_SELECT' });

        }
        
    }

};

const unloadedLeagueSelectState: LeagueSelectionState = {

    error: "",

    isLoading: true,

    league_selection_response: []

}

export const leagueSelectionReducer: Reducer<LeagueSelectionState> = (state: LeagueSelectionState | undefined, incomingAction: Action): LeagueSelectionState => { 

    if (state === undefined || state.league_selection_response === undefined) {

        return unloadedLeagueSelectState;

    }

    const action = incomingAction as LeagueSelectKnownAction;

    switch (action.type) {

        case 'RECEIVE_LEAGUE_SELECT':
            {

                return action as LeagueSelectionState;

            }
            
        case 'REQUEST_LEAGUE_SELECT':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
        {

             return state as LeagueSelectionState;

        }
        default:
        {

            return state;

        }

    }

};
