import { useRef, useState } from "react";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import ErrorBoundary from "../error/error_boundary";
import { Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MDBRow } from "mdbreact";
import { MDBCol } from "mdb-react-ui-kit";
import FixtureEvents from "./fixture_events";
import FixtureComments from "./fixture_comments";

export default function FixtureCommentary({ events, fixture_display_information, periods, comments, isMobile })
{
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);
    useIsVisible(isVisible, setIsVisible, componentMounted);
    const [viewType, setViewType] = useState(1);

    const handleViewTypeChange = (event, view) => {
        setViewType(view);
    };

    let views = [];

    if(comments.length > 0) {
        views.push({ name: "Commentary", id: 1 });
    }

    if(events.length > 0 && !isMobile) {
        views.push({ name: "Events", id: 2 } );
    }

    if((viewType != 2 && events.length > 0 && comments < 1) && !isMobile) {
        setViewType(2);
    }
    
    return events.length < 1 && comments.length < 1 ? <></> : (
        <ErrorBoundary>
          <Paper elevation={1} className={`mb-2 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
              <MDBRow style={{padding: '12px 0 12px 0' }}>
                  <MDBCol size='6' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <ToggleButtonGroup
                          className="fixture_period_toggle_group"
                          size='small'
                          value={viewType}
                          exclusive
                          onChange={handleViewTypeChange}
                          aria-label="text alignment"
                      >
                          {
                              views.filter(a => a.description !== 'penalties')
                              .map(data => (
                                  <ToggleButton color="primary" key={`event_type_${data.id}`} value={data.id} aria-label="left aligned">
                                      {data.name}
                                  </ToggleButton>
                              ))}
                      </ToggleButtonGroup>
                  </MDBCol>
                    {
                        viewType == 1 ?

                        <FixtureComments
                            className="fixture-commentary"
                            comments={comments}
                            fixture_display_information={fixture_display_information}
                        />

                        :

                        <FixtureEvents 
                            events={events.filter(a => a.section == 'event')}
                            fixture_display_information={fixture_display_information}
                            periods={periods} 
                        />
                    } 
              </MDBRow>
          </Paper>
      </ErrorBoundary>
    )
}