import React, { useState, useCallback, useMemo } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { AiOutlineSwap } from 'react-icons/ai';
import { CircularProgress, Paper } from "@mui/material";
import { GetGradientClass } from "../../../common/helpers/rating_helper";
import FixturePitch from "./fixture_pitch";
import ErrorBoundary from "../../error/error_boundary";
import { useLineups } from "../fixtureHook";

const BuildPlayerList = ({ players, isMobile, contentSetter, displayPlayerStats, setOpen, placeholderImage }) => (
    
    <ul className="underlying_list_team_lineup">
        {players.sort((a, b) => a.formation_position - b.formation_position).map((player) => (
            <li 
                // Uncomment this block if you want the onClick functionality back
                // onClick={() => {
                //     if (isMobile) {
                //         setOpen(true);
                //         displayPlayerStats(player.player_id);
                //     } else {
                //         contentSetter(isMobile ? 7 : 2, player.player_id);
                //     }
                // }}
                className="clickable list_item_lineup_player"
                key={player.id}
            >
                <img 
                    src={player.player_image || placeholderImage} 
                    alt={`${player.player_name} player profile image`} 
                    onError={(e) => { e.target.src = placeholderImage; }} 
                    className="player-image"
                />
                <label className="label_player_number">{player.jersey_number}</label>
                <div className="player-info">
                    <label className="label_player_name">{player.player_name}</label>
                    <span className={`${GetGradientClass(player.rating)} span_player_fixture_rating`}>
                        {player.rating?.toFixed(1) || "-"}
                    </span>
                </div>
            </li>
        ))}
    </ul>
);

const BuildLineupSection = ({ lineupData, teamId, teamName, teamLogo, isMobile, contentSetter, displayPlayerStats, setOpen, placeholderImage }) => (
    <MDBCol className="column_fixture_lineup" size={isMobile ? "12" : "6"}>
        <MDBRow>
            <MDBCol className="column_lineup_team_information" size="12">
                <MDBRow>
                    {/* onClick={() => contentSetter(isMobile ? 6 : 1, teamId)} */}
                    <MDBCol >
                        <img className="image_lineup_team_logo" src={teamLogo} alt={`${teamName} team logo`} />
                        <label className="label_line_team_name">{teamName}</label>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol className="column_team_lineup no_side_padding" size="12">
                <BuildPlayerList
                    players={lineupData.filter(player => player.type_name === 'Lineup')}
                    isMobile={isMobile}
                    contentSetter={contentSetter}
                    displayPlayerStats={displayPlayerStats}
                    setOpen={setOpen}
                    placeholderImage={placeholderImage}
                />
            </MDBCol>

            {
                lineupData.filter(player => player.type_name === 'Bench').length > 0 ? 
                <>
                    <div className="substitute_divider"><AiOutlineSwap size={25} />Substitutes</div>
                    <MDBCol className="column_team_lineup no_side_padding" size="12">
                        <BuildPlayerList 
                            players={lineupData.filter(player => player.type_name === 'Bench').sort((a, b) => a.jersey_number - b.jersey_number)}
                            isMobile={isMobile}
                            contentSetter={contentSetter}
                            displayPlayerStats={displayPlayerStats}
                            setOpen={setOpen}
                            placeholderImage={placeholderImage}
                        />
                    </MDBCol>
                </> : <></>
            }
        </MDBRow>
    </MDBCol>
);

export default function FixtureLineups({ setHasLineups, last_updated, fixture_id, home_team_id, away_team_id, home_team_name, away_team_name, home_team_logo, away_team_logo, contentSetter, isMobile }) {
    const [fixtureLineups, setFixtureLineups] = useState({ isLoading: true, data: null });
    const [stats, setSelectedPlayerStatistics] = useState([]);
    const [modalOpen, setOpen] = useState(false);

    useLineups(fixture_id, setFixtureLineups);

    const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

    const displayPlayerStats = useCallback((playerId) => {
        if (!modalOpen && playerId && fixtureLineups.data) {
            const players = [
                ...fixtureLineups.data.home_team_lineup,
                ...fixtureLineups.data.away_team_lineup,
                ...fixtureLineups.data.home_team_bench,
                ...fixtureLineups.data.away_team_bench,
            ];

            const selectedPlayer = players.find(player => player.player_id === playerId);
            if (selectedPlayer) {
                const fixturePlayerStatistics = selectedPlayer.fixture_player_statistics;
                fixturePlayerStatistics.player_image = selectedPlayer.player_image;
                if (fixturePlayerStatistics) {
                    setSelectedPlayerStatistics(fixturePlayerStatistics);
                    setOpen(true);
                }
            }
        }
    }, [modalOpen, fixtureLineups.data]);

    if (fixtureLineups.data == null && !fixtureLineups.isLoading) {
        if(setHasLineups != null) {
            setHasLineups(false);
        }
        
        return <></>;
    }

    if (fixtureLineups.isLoading) {
        return (
            <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <CircularProgress sx={{ margin: 'auto' }} />
            </Paper>
        );
    }

    return (
        <ErrorBoundary>
            {(fixtureLineups.data.home_team_lineup.length < 1 || fixtureLineups.data.away_team_lineup.length < 1) ? <></> :
                <Paper elevation={1} className={`mb-2 widget_fade_in`}>
                    <MDBRow className="row_lineup_tab no_side_padding">
                        <MDBCol className="no_side_padding pb-3" size='12'>
                            <label style={{ width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: 'bold' }}>Lineups</label>
                        </MDBCol>
                        {
                            !isMobile && <MDBCol className="no_side_padding" size='12'>
                                <FixturePitch 
                                    home_team_formation={fixtureLineups.data.home_team_formation}
                                    away_team_formation={fixtureLineups.data.away_team_formation}
                                    home_team_lineup={fixtureLineups.data.home_team_lineup.filter(a => a.type_name === 'Lineup')}
                                    away_team_lineup={fixtureLineups.data.away_team_lineup.filter(a => a.type_name === 'Lineup')}
                                    average_home_team_rating={fixtureLineups.data.average_home_team_rating}
                                    average_away_team_rating={fixtureLineups.data.average_away_team_rating}
                                    home_team_logo={home_team_logo}
                                    away_team_logo={away_team_logo}
                                />
                            </MDBCol>
                        }
  
                        <MDBCol className='no_side_padding pt-1' size='12'>
                            <MDBRow>
                                <BuildLineupSection 
                                    lineupData={fixtureLineups.data.home_team_lineup}
                                    teamId={home_team_id}
                                    teamName={home_team_name}
                                    teamLogo={home_team_logo}
                                    isMobile={isMobile}
                                    contentSetter={contentSetter}
                                    displayPlayerStats={displayPlayerStats}
                                    setOpen={setOpen}
                                    placeholderImage={placeholderImage}
                                />
                                <BuildLineupSection 
                                    lineupData={fixtureLineups.data.away_team_lineup}
                                    teamId={away_team_id}
                                    teamName={away_team_name}
                                    teamLogo={away_team_logo}
                                    isMobile={isMobile}
                                    contentSetter={contentSetter}
                                    displayPlayerStats={displayPlayerStats}
                                    setOpen={setOpen}
                                    placeholderImage={placeholderImage}
                                />
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </Paper>
            }
        </ErrorBoundary>
    );
}
