import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { MDBRow, MDBCol } from 'mdbreact';
import { ApplicationState } from '../../../store/index';
import * as ApplicationDataStore from '../../../store/application-state';
import { league_selection_item } from '../dashboard_system/interface_store';
import SecondaryDashboard from './secondary_dashboard';
import { HandleDefaultMenuSwitching } from '../dashboard_system/dashboard_switcher';
import StatManLogo from '../../../images/stat_man_logo_aqua.jpg';
import { iselected_content_dashboard_type, iselected_secondary_dashboard_type, primary_dashboard_menu, iselected_mobile_dashboard_type } from '../dashboard_system/dashboard_interfaces';
import { Skeleton } from '@mui/material';
import { Breakpoint } from 'react-socks';
import DashboardFooter from "./dashboard_footer";
import { withRouter } from "./withRouter";
import { getFixtureName } from "../../../api/fixtureAPI";
import '../../../mobile/application/dashboards/css/mobile_dashboard.css';
import '../css/primary_dashboard.css';
import '../css/content_dashboard.css';
import '../css/dashboard.css';
import { Link } from "react-router-dom";

type PrimaryDashboardProps = ApplicationState & 
                            typeof ApplicationDataStore.actionCreators & {
                                router: {
                                    params: {
                                        fixture_id?: string;
                                        fixture_name?: string;
                                    },
                                    navigate: (path: string, options?: { replace?: boolean }) => void;
                                };
                            };

class PrimaryDashboard extends PureComponent<PrimaryDashboardProps> {
    componentDidMount() {
        const { fixture_id, fixture_name } = this.props.router.params;

        this.props.requestLeagueSelections();
        if(fixture_id) {
            let fixtureId = parseInt(fixture_id);
            this.props.switchContentDashboard(iselected_content_dashboard_type.Fixture, fixtureId);
            this.props.switchMobileDashboard(iselected_mobile_dashboard_type.Fixture, fixtureId);
        }
    }

    async componentDidUpdate() {
        const { fixture_id, fixture_name } = this.props.router.params;
    
        if (fixture_id) {
            try {
                let fixtureId = parseInt(fixture_id);
                
                if (isNaN(fixtureId)) {
                    throw new Error(`Invalid fixture ID: ${fixture_id}`);
                }
    
                // Only update if the fixture ID or dashboard type has changed
                if (this.props.content_dashboard_state.selectedContentID !== fixtureId || 
                    this.props.mobile_dashboard_state.selectedDashboardType !== iselected_mobile_dashboard_type.Fixture) 
                {   
                    this.props.switchContentDashboard(iselected_content_dashboard_type.Fixture, fixtureId);
                    this.props.switchMobileDashboard(iselected_mobile_dashboard_type.Fixture, fixtureId);
                }
    
                const fixtureName = await getFixtureName(fixtureId);
                
                if (fixtureName !== fixture_name || fixture_name === '' || !fixture_name) {
                    this.updateUrlWithCorrectMatchName(fixture_id, fixtureName);
                }
            } 
            catch (error) {
                console.error(`Unable to parse fixture ${fixture_id} and set dashboards`, error);
            }
        }
    }
    
    updateUrlWithCorrectMatchName(fixture_id:string | undefined, correctMatchName:string) {
        this.props.router.navigate(`/fixture/${fixture_id}/${correctMatchName}`, { replace: true });
    }

    leagueSelectionOnClick = (leagueid: number | null) => {
        const { switchPrimaryDashboard, switchSecondaryDashboard, switchContentDashboard, content_dashboard_state } = this.props;
        
        switchPrimaryDashboard(leagueid);
        switchSecondaryDashboard(this.props.secondary_dashboard_state.selectedDashboardType);

        if (content_dashboard_state.selectedDashboardType === iselected_content_dashboard_type.Standing) {
            switchContentDashboard(content_dashboard_state.selectedDashboardType, leagueid ?? -1);
        }

        window.scrollTo(0, 0);
    };

    standingsSelectionOnClick = (league_id: number | null) => {
        this.props.switchContentDashboard(iselected_content_dashboard_type.Standing, league_id ?? -1);
        window.scrollTo(0, 0);
    };

    renderMenuItems = () => {
        return primary_dashboard_menu.menu_items.map((menu_item, index) => (
            <React.Fragment key={menu_item.menu_item_text}>
                {menu_item.menu_item_apply_seperator && <div className="dashboard_menu_seperator"></div>}
                <Link to={menu_item.menu_item_route}>
                    <li
                        onClick={() => HandleDefaultMenuSwitching(menu_item.menu_item_function_type, this.props)}
                        className="dashboard_list_item"
                    >
                        {<menu_item.menu_item_icon />}
                        {menu_item.menu_item_text}
                    </li>
                </Link>
                {menu_item.menu_item_apply_seperator && <div className="dashboard_menu_seperator"></div>}
            </React.Fragment>
        ));
    };

    renderLeagueSelections = () => {
        const { leagueSelectionState, primary_dashboard_state } = this.props;
        const { league_selection_response, isLoading } = leagueSelectionState;

        if (isLoading) {
            return Array.from(Array(15).keys()).map((index) => (
                <li key={"league_selection_" + index} style={{ marginTop: '5px' }}>
                    <Skeleton
                        sx={{ bgcolor: '#1a171e' }}
                        animation="wave"
                        height={44}
                        style={{
                            marginBottom: 6,
                            width: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            transform: 'unset',
                        }}
                    />
                </li>
            ));
        }

        return (
            <>
                {league_selection_response.map((value: league_selection_item) => (
                    <li
                        key={"league_profile_" + value.id}
                        className={
                            (value.id === primary_dashboard_state.selectedLeagueID ? "dashboard_league_list_item_selected " : "") +
                            "dashboard_league_list_item"
                        }
                        onClick={() => this.leagueSelectionOnClick(value.id)}
                    >
                        <span>{value.name}</span>
                    </li>
                ))}
            </>
        );
    };

    render() {
        const { site_date, primary_dashboard_state, switchSiteDate, mobile_dashboard_state, switchMobileDashboard } = this.props;

        return (
            <React.Fragment>
                <Breakpoint large up>
                    <MDBRow className="row_primary_dashboard">
                        <MDBCol className="dashboard_menu">
                            <img src={StatManLogo} className="the_stat_man_dashboard_logo" alt="The stat man logo" />
                            <ul className="dashboard_menu_list">{this.renderMenuItems()}</ul>
                            {/* <ul className="dashboard_menu_list">
                                <div className="dashboard_menu_seperator"></div>
                                <li className="dashboard_list_item">
                                    <AssessmentIcon />
                                    <a href="/dashboards/statistics/players">Statistics</a>
                                </li>
                                <div className="dashboard_menu_seperator"></div>
                            </ul> */}
                            <ul className="dashboard_menu_list_leagues">
                                <li
                                    className={
                                        (primary_dashboard_state.selectedLeagueID === null ? "dashboard_league_list_item_selected " : "") +
                                        "dashboard_league_list_item"
                                    }
                                    onClick={() => this.leagueSelectionOnClick(null)}
                                >
                                    <span>All</span>
                                </li>
                                {this.renderLeagueSelections()}
                            </ul>
                            <DashboardFooter />
                        </MDBCol>

                        <MDBCol className="column_no_side_padding primary_dashboard_secondary_column">
                            <MDBRow>
                                <MDBCol style={{ padding: '10px' }} size="12">
                                    <SecondaryDashboard />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </Breakpoint>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state,
    ApplicationDataStore.actionCreators
)(withRouter(PrimaryDashboard));
