import { useRef, useState } from "react";
import { useFixtureSchedule } from "./fixtureHook";
import ErrorBoundary from "../error/error_boundary";
import { Paper, Typography } from "@mui/material";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { BsClockFill } from "react-icons/bs";
import { BiCalendarEvent, BiFootball } from "react-icons/bi";
import { MdStadium } from "react-icons/md";

export default function FixtureSchedule({ fixture_id, teams, schedule }) {
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted); 

    const BuildCompetitionString = (information) => {
        var details = information.league.name;
        if(information.stage != null) {
            details = `${details}, ${information.stage.name}`; 
        }
        if(information.round != null) {
            details = `${details}, Round ${information.round.name}`;
        }
        return details;
    }

    const BuildVenueDetails = (information) => {
        var details = information.venue.name;
        if(information.venue.city != null) {
            details = `${details}, ${information.venue.city}`; 
        }
        return details;
    }

    return schedule.isLoading || (schedule.data == null && !schedule.isLoading) ? <></> : (
        <ErrorBoundary>
          <Paper elevation={1} className={`mb-2 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
          <MDBRow className="pt-3 pb-2">
                <MDBCol style={{ display: 'flex', alignItems: 'center' }}>
                    <BiFootball size={30} style={{ top: '-2px', position: 'relative' }} />
                    <div style={{ marginLeft: '8px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography 
                            sx={{ 
                                textAlign: 'left', 
                                fontWeight: 'bold', 
                                color: '#454545',
                                textTransform: 'uppercase', 
                                marginBottom: '2px',
                                lineHeight: '1'
                            }} 
                            variant="caption" 
                            component="span"
                        >
                            Fixture
                        </Typography>
                        <Typography 
                            variant="h1"
                            sx={{ 
                                letterSpacing: '-0.3px', 
                                fontSize: '14px !important',
                                fontWeight: '400'
                            }}
                        >
                            {teams.home_team.name} vs {teams.away_team.name} live scores, team lineups, league standings and statistics 
                        </Typography>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className="pt-3 pb-2">
                <MDBCol style={{ display: 'flex', alignItems: 'center' }}>
                    <BiCalendarEvent size={30} style={{ top: '-2px', position: 'relative' }} />
                    <div style={{ marginLeft: '8px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography 
                            sx={{ 
                                textAlign: 'left', 
                                fontWeight: 'bold', 
                                color: '#454545', // Removed the semicolon here
                                textTransform: 'uppercase', 
                                marginBottom: '2px', // Reduce the bottom margin
                                lineHeight: '1' // Adjust line height if needed
                            }} 
                            variant="caption" 
                            component="span"
                        >
                            Date and time
                        </Typography>
                        <Typography 
                            sx={{ 
                                letterSpacing: '-0.3px', 
                                fontSize: '14px' 
                            }} 
                            variant="body1" 
                            component="span"
                        >
                            {schedule.data.fixture_date} {schedule.data.fixture_time}
                        </Typography>
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow className="pt-2 pb-2">
                <MDBCol style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={schedule.data.league.image_path} title={schedule.data.league.name + " league logo"} alt={schedule.data.league.name + " league logo"} style={{ width: '30px', height: '30px', top: '-2px', position: 'relative'  }} />
                    <div style={{ marginLeft: '8px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography 
                            sx={{ 
                                textAlign: 'left', 
                                fontWeight: 'bold', 
                                color: '#454545', 
                                textTransform: 'uppercase', 
                                marginBottom: '2px',
                                lineHeight: '1'
                            }} 
                            variant="caption" 
                            component="span"
                        >
                            Competition
                        </Typography>
                        <Typography 
                            sx={{ 
                                letterSpacing: '-0.3px', 
                                fontSize: '14px' 
                            }} 
                            variant="body1" 
                            component="span"
                        >
                            {BuildCompetitionString(schedule.data)}
                        </Typography>
                    </div>
                </MDBCol>
            </MDBRow>
            { schedule.data.venue && <MDBRow className="pt-2 pb-2">
                <MDBCol style={{ display: 'flex', alignItems: 'center' }}>
                    <MdStadium size={30} style={{ top: '-2px', position: 'relative' }} />
                    <div style={{ marginLeft: '8px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography 
                            sx={{ 
                                textAlign: 'left', 
                                fontWeight: 'bold', 
                                color: '#454545', 
                                textTransform: 'uppercase', 
                                marginBottom: '2px', // Reduce the bottom margin
                                lineHeight: '1' // Adjust line height if needed
                            }} 
                            variant="caption" 
                            component="span"
                        >
                            Venue
                        </Typography>
                        <Typography 
                            sx={{ 
                                letterSpacing: '-0.3px', 
                                fontSize: '14px' 
                            }} 
                            variant="body1" 
                            component="span"
                        >
                            {BuildVenueDetails(schedule.data)}
                        </Typography>
                    </div>
                </MDBCol>
            </MDBRow>
            }
            { schedule.data.country && <MDBRow className="pt-2 pb-3">
                <MDBCol style={{ display: 'flex', alignItems: 'center' }}>
                <img src={schedule.data.country.image_path} title={schedule.data.country.name + " flag"} alt={schedule.data.country.name + " flag"} style={{ borderRadius: '50%', border: '1px solid #dcdcdc', width: '30px', height: '30px', top: '-2px', position: 'relative'  }} />
                    <div style={{ marginLeft: '8px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography 
                            sx={{ 
                                textAlign: 'left', 
                                fontWeight: 'bold', 
                                color: '#454545', 
                                textTransform: 'uppercase', 
                                marginBottom: '2px', // Reduce the bottom margin
                                lineHeight: '1' // Adjust line height if needed
                            }} 
                            variant="caption" 
                            component="span"
                        >
                            Country
                        </Typography>
                        <Typography 
                            sx={{ 
                                letterSpacing: '-0.3px', 
                                fontSize: '14px' 
                            }} 
                            variant="body1" 
                            component="span"
                        >
                            {schedule.data.country.name}
                        </Typography>
                    </div>
                </MDBCol>
            </MDBRow>
            }
          </Paper>
      </ErrorBoundary>
    )
}