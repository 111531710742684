import {IconType} from 'react-icons';
import { AiOutlineHome } from 'react-icons/ai';
import { BsCalendar3, BsPersonCircle } from 'react-icons/bs';
import { RiTeamLine } from 'react-icons/ri';

export interface IMobile {
    
    isMobile: boolean

}

export interface iprimary_dashboard_state {

    menu_items: iprimary_dashboard_menu_item[];

    selectedLeagueID?: number | null;

}

export interface isecondary_dashboard_state {

    selectedDashboardType: iselected_secondary_dashboard_type;

}

export interface icontent_dashboard_state {

    selectedDashboardType: iselected_content_dashboard_type | null;

    selectedContentID?: number | null;

}

export interface imobile_dashboard_state {

    selectedDashboardType: iselected_mobile_dashboard_type;

    selectedContentID?: number | null;

}

export interface iprimary_dashboard_menu_item {

    menu_item_text: string;

    menu_item_route: string;

    menu_item_icon: IconType;

    menu_item_apply_seperator: boolean;

    menu_item_function_type: iselected_secondary_dashboard_type

}

export enum iselected_secondary_dashboard_type {

    Home = 1,

    Insights = 2,

    FixtureAndResults = 3,

    Players = 4,

    // Standings = 5,

    Teams = 6,

    ScoresAndFixtures = 7

}

export enum iselected_content_dashboard_type {

    Team = 1,

    Player = 2,

    Fixture = 3,

    Standing = 4,

    League = 5,

    HomeContent = 6,

    Insights = 7

}

export enum iselected_mobile_dashboard_type {

    Home = 1,

    LiveScores = 2,

    Players = 3,

    Standings = 4,

    Teams = 5,

    Team = 6,

    Player = 7,

    League = 8,

    Standing = 9,

    Fixture = 10,

    Insights = 11,

    FixtureDifficulty = 12,

    GoLive = 13,

}

export const primary_dashboard_menu = {

    menu_items: [
        
        { menu_item_function_type: iselected_secondary_dashboard_type.Home, menu_item_route: '/', menu_item_text: "Home", menu_item_icon: AiOutlineHome, menu_item_apply_seperator: true } as iprimary_dashboard_menu_item,

        { menu_item_function_type: iselected_secondary_dashboard_type.ScoresAndFixtures, menu_item_route: '/', menu_item_text: "Livescores", menu_item_icon: BsCalendar3, menu_item_apply_seperator: false } as iprimary_dashboard_menu_item,

        // { menu_item_function_type: iselected_secondary_dashboard_type.Standings, menu_item_text: "Standings", menu_item_icon: GoListOrdered, menu_item_apply_seperator: false } as iprimary_dashboard_menu_item,

        // { menu_item_function_type: iselected_secondary_dashboard_type.Teams, menu_item_text: "Teams", menu_item_icon: RiTeamLine },

        // { menu_item_function_type: iselected_secondary_dashboard_type.Players, menu_item_text: "Players", menu_item_icon: BsPersonCircle, menu_item_apply_seperator: false } as iprimary_dashboard_menu_item,

        // { menu_item_function_type: iselected_secondary_dashboard_type.Insights, menu_item_text: "Insights", menu_item_icon: ImStatsDots, menu_item_apply_seperator: false } as iprimary_dashboard_menu_item

    ] as iprimary_dashboard_menu_item[]

} as iprimary_dashboard_state;
