import { useState } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import '../css/fixture_dashboard.css';
import { useFixtureDashboard, useFixtureSchedule } from '../../fixtures/fixtureHook';
import FixtureLineups from '../../fixtures/lineups/fixture_lineups';
import FixturePenalties from '../../fixtures/fixture_penalties';
import FixtureStatistics from '../../fixtures/fixture_statistics';
import FixtureDetails from '../../fixtures/fixture_details';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import MatchOfficials from '../../fixtures/match_officials';
import { useLiveFixtureSubscription } from '../../infrastructure/subscriptions/fixtureSubscriptionHook';
import FixtureCommentary from '../../fixtures/fixture_commentary';
import FixtureSchedule from '../../fixtures/fixture_schedule';
import errorImg from '../../../images/opps_something_went_wrong.jpg';
import FixtureHelmet from '../../helmets/fixture_helmet';

const FixtureDashboard = ({ fixture_id, contentSetter, isMobile, site_date }) => {
    const [fixtureDashboardResponse, setFixtureDashboard] = useState({
        isLoading: true,
        data: null,
        fixture_id,
        last_updated: null
    });

    const [formats, setFormats] = useState(['commentary', 'lineups', 'statistics', 'form']);
    const [schedule, setFixtureSchedule] = useState({ data: null, isLoading: true });

    useFixtureDashboard(fixture_id, setFixtureDashboard);
    useLiveFixtureSubscription(fixture_id, setFixtureDashboard);
    useFixtureSchedule(fixture_id, setFixtureSchedule);

    if(fixtureDashboardResponse.data === null && !fixtureDashboardResponse.isLoading) {
        return (
        <Paper style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <MDBRow>
                <MDBCol size='12'>
                    <img src={errorImg} alt='no available fixture' style={{height: '300px', width: '275px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} />
                </MDBCol>
                <MDBCol size='12'>
                    <Typography style={{textAlign: 'center'}} variant="h5">An error occured</Typography>
                    <Typography style={{textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} variant="caption">The fixture you where looking for could not be found</Typography>
                </MDBCol>
            </MDBRow>
        </Paper>)
    }

    if (!fixtureDashboardResponse.data ||
        fixtureDashboardResponse.isLoading ||
        fixture_id !== fixtureDashboardResponse.fixture_id) 
    {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Box sx={{ width: '100%', height: '400px', padding: '40px' }}>
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }} />
                </Box>
            </div>
        );
    }

    const 
    {
        data: {
            events,
            periods,
            comments,
            fixture_display_information: displayInfo,
            period_statistics: periodStats,
            fixture_statistics: stats,
            last_updated
        },
    } = fixtureDashboardResponse;

    let teams = {
        home_team: { id: displayInfo.home_team_id, name: displayInfo.home_team_name, logo: displayInfo.home_team_logo }, 
        away_team: { id: displayInfo.away_team_id, name: displayInfo.away_team_name, logo: displayInfo.away_team_logo },  
    }

    const renderPenalties = () => {
        const penaltyPeriod = periods.find((period) => period.description === 'penalties');
        if (!penaltyPeriod) return null;

        return (
            <MDBCol className="no_side_padding" size="12">
                <FixturePenalties
                    events={events}
                    period={penaltyPeriod}
                    home_team_logo={displayInfo.home_team_logo}
                    away_team_logo={displayInfo.away_team_logo}
                    home_team_id={displayInfo.home_team_id}
                    away_team_id={displayInfo.away_team_id}
                />
            </MDBCol>
        );
    };

    const renderEvents = () => {
        if (!formats.includes('commentary') || (comments.length < 1 && events.length < 1)) return null;

        return (
            <MDBCol className="no_side_padding" size="12">
                <FixtureCommentary
                    events={events}
                    periods={periods}
                    comments={comments}
                    fixture_display_information={displayInfo}
                />
            </MDBCol>
        );
    };

    const renderStatistics = () => {
        if (!formats.includes('statistics') || (periods.length < 1 && periodStats.length < 1)) return null;

        return (
            <MDBCol className="no_side_padding" size="12">
                <FixtureStatistics
                    periods={periods}
                    fixture_id={fixture_id}
                    fixture_statistics={stats}
                />
            </MDBCol>
        );
    };

    const renderLineups = () => {
        if (!formats.includes('lineups')) return null;

        return (
            <MDBCol className="no_side_padding" size="12">
                <FixtureLineups
                    fixture_id={fixture_id}
                    last_updated={last_updated}
                    home_team_id={displayInfo.home_team_id}
                    away_team_id={displayInfo.away_team_id}
                    home_team_name={displayInfo.home_team_name}
                    away_team_name={displayInfo.away_team_name}
                    home_team_logo={displayInfo.home_team_logo}
                    away_team_logo={displayInfo.away_team_logo}
                    contentSetter={contentSetter}
                    isMobile={isMobile}
                />
            </MDBCol>
        );
    };

    const renderFixtureSchedule = () => {
        return (
            <MDBCol className="no_side_padding" size="12">
                <FixtureSchedule fixture_id={fixture_id} teams={teams} schedule={schedule} />
            </MDBCol>
        )
    };

    return (
        <>
            <FixtureHelmet fixtureData={fixtureDashboardResponse.data} schedule={schedule} teams={teams} fixture_id={fixture_id} />
            <MDBRow>
                <MDBCol className="no_side_padding" size={isMobile ? "12" : "6"}>
                    <MDBRow>
                        <MDBCol className="no_side_padding" size="12">
                            <FixtureDetails
                                isMobile={isMobile}
                                contentSetter={contentSetter}
                                fixture_id={fixture_id}
                                detailsResponse={fixtureDashboardResponse}
                                last_updated={last_updated}
                            />
                        </MDBCol>
                        {renderPenalties()}
                        {renderEvents()}
                        {renderFixtureSchedule()}
                        {/* <MDBCol className="no_side_padding" size="12">
                            <MatchOfficials
                                isMobile={isMobile}
                                contentSetter={contentSetter}
                                fixture_id={fixture_id}
                            />
                        </MDBCol> */}
                    </MDBRow>
                </MDBCol>

                <MDBCol style={{ paddingLeft: isMobile ? '0' : '10px' }} className="no_side_padding" size={isMobile ? "12" : "6"}>
                    {renderStatistics()}
                </MDBCol>
            </MDBRow>

            <MDBRow>
                <MDBCol className="no_side_padding" size="12">
                    {renderLineups()}
                </MDBCol>
            </MDBRow>
        </>
    );
};

export default FixtureDashboard;
