import React, { useRef, useState } from "react";
import {
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled
} from "@mui/material";
import { useStandings } from "./standingsHook";
import { MDBCol } from "mdb-react-ui-kit";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { tableCellClasses } from '@mui/material/TableCell';

// Styled Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0d1e3312',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
        fontSize: 14,
    },
}));

export default function LeagueTable({ contentSetter, season_id, team_ids, isMobile }) {
    const componentMounted = useRef(true);
    const [isVisible, setIsVisible] = useState(false);
    const [leagueStandingsResponse, setLeagueStandings] = useState({
        isLoading: false,
        data: null,
        season_id: season_id,
        round_id: -1,
    });

    useIsVisible(isVisible, setIsVisible, componentMounted);
    useStandings(season_id, leagueStandingsResponse.round_id, setLeagueStandings);

    if (leagueStandingsResponse.isLoading || !leagueStandingsResponse.data) {
        return (
            <LoadingSpinner />
        );
    }

    const stage = leagueStandingsResponse.season_id === -1
        ? leagueStandingsResponse.data[0]
        : leagueStandingsResponse.data.find(a => a.season_id === leagueStandingsResponse.season_id);

    if (!stage) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <Paper elevation={1} className={`mb-2 pb-3 widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
            <MDBCol className="no_side_padding">
                {!isMobile && (
                    <MDBCol className="no_side_padding pt-3 mb-2" size='12'>
                        <label className="section_header no_background">Standings</label>
                    </MDBCol>
                )}
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>#</StyledTableCell>
                                <StyledTableCell>Team</StyledTableCell>
                                <StyledTableCell align="center">P</StyledTableCell>
                                <StyledTableCell align="center">W</StyledTableCell>
                                <StyledTableCell align="center">D</StyledTableCell>
                                <StyledTableCell align="center">L</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                                <StyledTableCell align="center">PTS</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stage.rounds.find(a => 
                                leagueStandingsResponse.round_id === -1 ? a.is_current : a.id === leagueStandingsResponse.round_id
                            ).standings.sort((a, b) => a.position - b.position).map((standing, index) => (
                                <StyledTableRow key={"league_standings_team_" + standing.participant_id} style={{ background: team_ids.includes(standing.participant_id) ? "#2F4369" : "" }}>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '', paddingLeft: '5px', paddingRight: '5px' }} align="center">
                                        {standing.position}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '', paddingLeft: '5px', paddingRight: '5px' }}>
                                        <img style={{ width: '20px', height: '20px', marginRight: '8px', position: 'relative', top: '-2px' }} src={standing.image_path} alt={`${standing.team_name} logo`} />
                                        {standing.team_short_name || standing.team_name.toUpperCase().substring(0, 3)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '' }} align="center">
                                        {standing.details.find(a => a.type_id === 129).value}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '' }} align="center">
                                        {standing.details.find(a => a.type_id === 130).value}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '' }} align="center">
                                        {standing.details.find(a => a.type_id === 131).value}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '' }} align="center">
                                        {standing.details.find(a => a.type_id === 132).value}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '', paddingLeft: '0px', paddingRight: '0px' }} align="center">
                                        {GetStandingResultIcon(standing.result)}
                                    </StyledTableCell>
                                    <StyledTableCell style={{ color: team_ids.includes(standing.participant_id) ? 'white' : '' }} align="center">
                                        {standing.points}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </MDBCol>
        </Paper>
    );
}

// Loading Spinner Component
function LoadingSpinner() {
    return (
        <Box className="loading_spinner" sx={{ height: '800px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    );
}

// Standing Result Icon Function
function GetStandingResultIcon(result) {
    switch (result) {
        case "equal":
            return "-";
        case "up":
            return <BiChevronUp size={25} color="green" />;
        case "down":
            return <BiChevronDown size={25} color="red" />;
        default:
            return null;
    }
}
