import { useState, useRef } from "react";
import { useResults } from "../fixtures/fixtureHook";
import { BsClockFill } from 'react-icons/bs';
import { MDBRow, MDBCol } from 'mdbreact';
import { SetStatusClasses, GetFixtureState } from "../../common/helpers/status_helper";
import LiveScoreWidgetSkeleton from "../loading/skeletons/live_Score_widget_skeleton";
import { useIsVisible } from "../shared/hooks/isVisibleHook";
import { useLiveScoresSubscription } from "../infrastructure/subscriptions/fixtureSubscriptionHook";
import { DateController } from "../shared/date/button_date_picker";
import '../dashboards/css/fixtures_and_Results_dashboard.css';
import { Typography } from "@mui/material";
import { FaRegCalendar } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function LiveScoresWidget(props) {
    const { currentLeagueID, isMobile, contentSetter, site_date, switch_site_date } = props;

    const componentMounted = useRef(true);
    const [resultsState, setFixtureResultsState] = useState({ isLoading: true, data: null });
    const [isVisible, setIsVisible] = useState(false);

    useIsVisible(isVisible, setIsVisible, componentMounted);
    useResults(site_date, currentLeagueID, setFixtureResultsState);
    useLiveScoresSubscription(site_date, setFixtureResultsState);

    if (resultsState === null || resultsState.isLoading) {
        return <LiveScoreWidgetSkeleton />;
    }

    const { data } = resultsState;

    const filteredLeagues = data?.league_profiles?.filter((league) => 
        currentLeagueID === -1 || currentLeagueID === null ? true : league.league_id === currentLeagueID
    );

    return (
        <div className={`dashboard_widget widget_fade_in ${isVisible ? 'fade-in' : ''}`}>
            <label className="dashboard_widget_controls">
                <span>Scores</span>
                <span>
                    <DateController selected_date={site_date} set_date={switch_site_date} />
                </span>
            </label>

            <div style={{ paddingBottom: '15px' }}>
                {(!data || !filteredLeagues || filteredLeagues.length < 1) ? (
                    <MDBRow>
                        <MDBCol size="12" className="p-5">
                            <BsClockFill color="#0D1E33" style={{ margin: '0 auto', display: 'block' }} size={100} />
                        </MDBCol>
                        <MDBCol size="12">
                            <p className="label_no_results_heading">No Scheduled Fixtures</p>
                            <p className="label_no_results_sub_heading">Try selecting a future date from the date picker above to view upcoming fixtures.</p>
                            <p className="label_no_results_sub_heading">Alternatively select a date from the past to view past results and statistics.</p>
                        </MDBCol>
                    </MDBRow>
                ) : (
                    filteredLeagues.map((league, index) => (
                        <div key={`competition_${league.league_id}`} className="event_details_row">
                            <div className="event_competition_row">
                                <img
                                    className="image_competition_logo"
                                    src={league.league_logo}
                                    alt={`${league.league_name} logo`}
                                />
                                <div className="div_competition_labels">
                                    <Typography component="div">{league.league_name}</Typography>
                                </div>
                            </div>

                            {data.results.filter(result => result.league_id === league.league_id).map((result) => (
                                <Link key={`event_row_${result.fixture_id}`} to={`/fixture/${result.fixture_id}/${result.fixture_name}`}>
                                <div
                                    onClick={() => contentSetter(isMobile ? 10 : 3, result.fixture_id)}
                                    className="clickable event_row"
                                >
                                    <div className="event_container">
                                        <div className="event_information">
                                            <div className="event_state">
                                                <div className="event_time_status"> {/* New wrapper for stacking */}
                                                    <span className="event_date">{result.fixture_time}</span>
                                                    <div className={`current_event_state ${SetStatusClasses(result.fixture_state)} event_status`}>
                                                        {result.fixture_state === "NS" ? "-" : GetFixtureState(result, false)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="event_column_teams">
                                                <div className="live_score_teams">
                                                    <div className="teams_container">
                                                        <div className="team_name">
                                                            <img src={result.home_team_logo} alt={result.home_team_name + " logo"} />
                                                            {result.home_team_name}
                                                        </div>
                                                        <div className="team_name">
                                                            <img src={result.away_team_logo} alt={result.away_team_name + " logo"} />
                                                            {result.away_team_name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="event_column_scores">
                                                <div className="event_result">
                                                    <div className="score_container">
                                                        <span className={`${SetStatusClasses(result.fixture_state)}`}>
                                                            <b>{result.home_win ? result.home_team_score : ''}</b>
                                                            {!result.home_win ? result.home_team_score : ''}
                                                        </span>
                                                        <span className={`${SetStatusClasses(result.fixture_state)}`}>
                                                            <b>{result.away_win ? result.away_team_score : ''}</b>
                                                            {!result.away_win ? result.away_team_score : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            ))}

                            {index !== filteredLeagues.length - 1 && (
                                <div className="competition_seperator" />
                            )}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
}
