/**
     * Converts integer to a hexidecimal code, prepad's single 
     * digit hex codes with 0 to always return a two digit code. 
     * 
     * @param {Integer} i Integer to convert 
     * @returns {String} The hexidecimal code
     */
 function intToHex(i) {
    var hex = parseInt(i).toString(16);
    return (hex.length < 2) ? "0" + hex : hex;
}   

/**
 * Return hex color from scalar *value*.
 *
 * @param {float} value Scalar value between 0 and 1
 * @return {String} color
 */
export function CreateHexColour(value) {

    // value must be between [0, 510]
    value = Math.min(Math.max(0, value), 0.5) * 510;

    var redValue;
    var greenValue;
    if (value < 255) {
        redValue = 255;
        greenValue = Math.sqrt(value) * 15;
        greenValue = Math.round(greenValue);
    } else {
        greenValue = 255;
        value = value - 255;
        redValue = 256 - (value * value / 255)
        redValue = Math.round(redValue);
    }

    return "#" + intToHex(redValue) + intToHex(greenValue) + "00";
}

export function GetGradientClass(rating)
{

    let convertedRating = parseFloat(rating);

    if(convertedRating >= 10) {

        return "gradient_0";

    }

    if(convertedRating < 10 && convertedRating >= 9) {

        return "gradient_10";

    }

    if(convertedRating < 9 && convertedRating >= 8) {

        return "gradient_20";

    }

    if(convertedRating < 8 && convertedRating >= 7) {

        return "gradient_40";

    }

    if(convertedRating < 7 && convertedRating >= 6) {

        return "gradient_60";

    }

    if(convertedRating < 6 && convertedRating >= 5.5) {

        return "gradient_80";

    }

    if(convertedRating === null || convertedRating === undefined || convertedRating === 0 || isNaN(convertedRating)) {

        return "gradient_unset";

    }

    return "gradient_100";

}