import { useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BsCalendarMinus } from "react-icons/bs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const useStyles = makeStyles({

  container: {

    display: "flex",

    flexDirection: "column",

    height: '100%'

  },

});

function ConvertStateDate(stateDate) {

  var dateParts = stateDate.split("/");

  // month is 0-based, that's why we need dataParts[1] - 1
  
  return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

}

function ConvertToPickerDate(stateDate) {

  if(stateDate === null || stateDate === undefined || stateDate === '') {

    return GetTodaysDate();

  }

  var dateParts = stateDate.split("/");

  var date = (dateParts[1]) + "/" + dateParts[0] + "/" + dateParts[2]; 

  // month is 0-based, that's why we need dataParts[1] - 1

  return date;

}

function GetTodaysDate() {

  var today = new Date();

  var dd = String(today.getDate()).padStart(2, '0');

  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!

  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;
  
  return today;

}

export function MobileDateController(props) {

  const classes = useStyles();

  const { set_date, selected_date } = props;

  const [isOpen, setIsOpen] = useState(false);

  var dateObject = ConvertToPickerDate(selected_date);

  return (

    <LocalizationProvider dateAdapter={AdapterDateFns}>

        <div className={classes.container}>

          <Button startIcon={<BsCalendarMinus />} style={{color: 'white', height:'100%', width:'50%'}} onClick={() => setIsOpen(true)} />

            <span style={{color: 'white', display: 'none'}}>

              <DatePicker

                label="Site Date"

                value={dateObject}

                inputFormat="MM/dd/yyyy"

                open={isOpen}

                onOpen={() => setIsOpen(true)}

                onClose={() => setIsOpen(false)}

                onChange={(newValue) => {

                  set_date(new Date(newValue).toLocaleDateString());

                }}

                renderInput={(params) => <TextField {...params} />}

              />

          </span>

      </div>

      </LocalizationProvider>

  );

}