import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { MDBCol } from "mdb-react-ui-kit";
import { useCallback, useState } from "react";

export default function ScoringMinutes({ statPack, team_id })
{
    const [conceded, setConceded] = useState(0);

    const handleChange = useCallback((event, value) => {
        setConceded(value);
    }, []);

    var team_statistics_scoring_minutes = conceded === 0 ? statPack.team_statistics_scoring_minutes : statPack.team_statistics_scoring_conceded_minutes;

    return (
        <BuildScoringMinutesTable 
            team_statistics_scoring_minutes={team_statistics_scoring_minutes}
            handleChange={handleChange}
            team_id={team_id}
            conceded={conceded}
        />
    )
}

const BuildScoringMinutesTable = ({ team_statistics_scoring_minutes, conceded, handleChange, team_id }) => (
    <MDBCol size="12" className="no_side_padding">
        <MDBCol className="mb-3" size='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ToggleButtonGroup
                className="fixture_period_toggle_group"
                size='small'
                value={conceded}
                exclusive
                onChange={handleChange}
                aria-label="text alignment"
            >
                <ToggleButton color="primary" value={0} aria-label="left aligned">
                    Scoring Minutes
                </ToggleButton>
                <ToggleButton color="primary" value={1} aria-label="left aligned">
                    Conceded Scoring Minutes
                </ToggleButton>
            </ToggleButtonGroup>
        </MDBCol>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell style={{textAlign: 'center'}}>Goal Count</TableCell>
                        <TableCell style={{textAlign: 'center'}}>% of Goals</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        team_statistics_scoring_minutes.scoring_minutes.find(stat => stat.team_id === team_id)?.scoring_minute_values.map((stat, index) => {
                            return (<TableRow
                                key={"goal_results_" + index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                
                            >
                                <TableCell component="th" scope="row">
                                    {stat.display_Name}
                                </TableCell>
                                <TableCell style={{textAlign: 'center'}} className={`stat-pack-value insight-${stat.period_Count > 5 ? 'high' : stat.period_Count}`}>{stat.period_Count}</TableCell>
                                <TableCell style={{textAlign: 'center'}}>{stat.period_Percentage}</TableCell>
                            </TableRow>)
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </MDBCol>
);