import { MDBRow, MDBCol } from 'mdbreact';

import { IMobile, iselected_mobile_dashboard_type } from './dashboard_interfaces';
import HideAppBar from '../../../Components/navigation/top_menu';
import PlayersDashboard from '../../player/player_search_widget';
import PlayerDashboard from '../views/player_dashboard';
import TeamDashboard from '../views/team_dashboard';
import LiveScoresWidget from '../../livescores/livescores_widget';
import StandingsDashboard from '../views/standings_dashboard';
import LeagueDashboard from '../views/league_dashboard';
import InsightsDashboard from '../views/insights_content_dashboard';
import InsightSecondaryDashboard from '../views/insight_secondary_dashboard';
import TeamsDashboard from '../../teams/team_search_widget';
import LiveInsights from '../../insights/live_insights';
import FixtureDashboard from '../../../mobile/application/dashboards/views/fixture_dashboard';

type IsMobile = { isMobile: true };

export default function SwitchMobileDashboardContent(props:any) { 

    const 
    { 
        
        content_setter,

        currentContentID,

        mobile_dashboard_type,
        
        current_league_id,

        site_date,

        switch_site_date
    
    } = props;

    if(currentContentID === 0) {

        return;

    }

    window.scrollTo(0, 0);

    return (
        <>
            <HideAppBar selected_date={site_date} set_date={switch_site_date} setter={() => content_setter(1, null)} />
            <MDBRow style={{height: '100%'}}>
                <MDBCol className="column_mobile_dashboard" xs="12" sm="12" md="12">
                    {
                        GetMobileDashboardContent(mobile_dashboard_type, content_setter, currentContentID, current_league_id, site_date, switch_site_date)
                    }
                </MDBCol>
            </MDBRow>
        </>
    )
}

function GetMobileDashboardContent(
    
    mobile_dashboard_type: iselected_mobile_dashboard_type, 
    
    content_setter: any, 
    
    current_content_id: number,

    current_league_id: number,

    site_date: string,

    switch_site_date: Function
    
) : JSX.Element
{
    switch(mobile_dashboard_type)
    {
        default:
        case iselected_mobile_dashboard_type.LiveScores:
        case iselected_mobile_dashboard_type.Home:
            {

                return <LiveScoresWidget 
                
                    contentSetter={content_setter} 

                    {...{isMobile: true} as IMobile} 
                    
                    currentLeagueID={current_league_id}

                    site_date={site_date}

                    switch_site_date={switch_site_date}

                />

            }
        case iselected_mobile_dashboard_type.Fixture:
            {

                return <FixtureDashboard
                
                    isMobile={true} 
                    
                    fixture_id={current_content_id} 
                    
                    contentSetter={content_setter} 

                    site_date={site_date}
                    
                />

            }

        case iselected_mobile_dashboard_type.Players:
            {

                return <PlayersDashboard 
                
                    contentSetter={content_setter} 
                    
                    currentContentID={current_content_id} 
                    
                    currentLeagueID={current_league_id}

                    isMobile={true}
                    
                />

            }

        case iselected_mobile_dashboard_type.League:
                {
    
                    return <LeagueDashboard 
                    
                        league_id={current_content_id} 
                        
                        contentSetter={content_setter} 
                        
                        isMobile={true} 
                        
                    />
    
                }

        case iselected_mobile_dashboard_type.Team:
            {

                return <TeamDashboard 
                
                    team_id={current_content_id} 
                    
                    contentSetter={content_setter} 

                    isMobile={true}
                    
                />

            }

        case iselected_mobile_dashboard_type.Player:
            {

                return <PlayerDashboard 
                
                    player_id={current_content_id} 
                    
                    contentSetter={content_setter} 

                    isMobile={true} 
                    
                />

            }

        case iselected_mobile_dashboard_type.Standing:
            {

                return <StandingsDashboard 

                    league_id={current_content_id} 
                    
                    contentSetter={content_setter}

                    isMobile={true} 
                    
                />

            }

        case iselected_mobile_dashboard_type.Teams:
            {

                return <TeamsDashboard 

                    isMobile={true} 
                
                    contentSetter={content_setter}
                    
                    currentLeagueID={current_league_id}
                    
                />

            }

        case iselected_mobile_dashboard_type.Insights:
            {

                return <InsightsDashboard 
                
                    fixture_id={current_content_id}

                    current_league_id={current_league_id} 
                    
                    contentSetter={content_setter} 
                    
                    isMobile={true} 

                    site_date={site_date}
                    
                />

            }

        case iselected_mobile_dashboard_type.FixtureDifficulty:
            {

                return <InsightSecondaryDashboard 
                
                    currentLeagueID={current_league_id}  
                    
                    contentSetter={content_setter} 
                    
                    isMobile={true} 

                    site_date={site_date}
                    
                />

            }

        case iselected_mobile_dashboard_type.GoLive:
            {

                return <LiveInsights 
                
                    current_league_id={current_league_id}

                    fixture_id={current_content_id} 
                    
                    contentSetter={content_setter} 
                    
                    isMobile={true} 

                    site_date={site_date}
                    
                />

            }

    }

}
