import React from 'react';

import { MDBRow, MDBCol } from 'mdbreact';

import { IMobile, iselected_secondary_dashboard_type, iselected_content_dashboard_type } from './dashboard_interfaces';

import PlayersSearchWidget from '../../player/player_search_widget';
import FixtureDashboard from '../views/fixture_dashboard';
import PlayerDashboard from '../views/player_dashboard';
import TeamDashboard from '../views/team_dashboard';
import LeagueStandingsFull from '../views/standings_dashboard';
import LeagueDashboard from '../views/league_dashboard';
import LiveScoresWidget from '../../livescores/livescores_widget';
import TeamsSearchWidget from '../../teams/team_search_widget';
import HomeDashboard from '../views/home_dashboard';
import InsightContentDashboard from '../views/insights_content_dashboard';
import ErrorBoundary from '../../error/error_boundary';
import InsightSecondaryDashboard from '../views/insight_secondary_dashboard';

export default function SwitchDashboardContent(props:any) {

    const 
    {
        
        content_setter,

        currentContentID,

        secondary_dashboard_type,
        
        content_dashboard_type,

        current_league_id,

        site_date,

        switch_site_date
    
    } = props;

    window.scrollTo(0, 0);

    return (

        <React.Fragment>

            <MDBRow>

                <MDBCol className="column_secondary_dashboard no_side_padding" xs="4" sm="4" md="4" lg="4" xl="4">

                    {

                        GetSecondaryDashboard(secondary_dashboard_type, content_setter, currentContentID, current_league_id, site_date, switch_site_date)

                    }

                </MDBCol>

                <MDBCol className="column_content_dashboard" xs="8" sm="8" md="8" lg="8" xl="8"> 

                    {

                        GetContentDashboard(content_dashboard_type, content_setter, currentContentID, current_league_id, site_date)

                    }

                </MDBCol>

            </MDBRow>

        </React.Fragment>

    )

}

function GetSecondaryDashboard(
    
    secondary_dashboard_type: iselected_secondary_dashboard_type,
    
    content_setter: any, 
    
    current_content_id: number,

    current_league_id: number,

    site_date: string,

    switch_site_date: Function
    
) : JSX.Element
{

    switch(secondary_dashboard_type)
    {

        default:
        case iselected_secondary_dashboard_type.Home:
        case iselected_secondary_dashboard_type.ScoresAndFixtures:
            {

                return <ErrorBoundary>

                    <LiveScoresWidget 
                
                        isMobile={false} 
                        
                        currentLeagueID={current_league_id} 
                        
                        contentSetter={content_setter} 

                        site_date={site_date}

                        switch_site_date={switch_site_date}
                        
                    />
                
                </ErrorBoundary>

            }
        case iselected_secondary_dashboard_type.Insights:
            {

                return <InsightSecondaryDashboard
                
                    isMobile={false} 
                        
                    fixture_id={current_content_id} 

                    currentLeagueID={current_league_id} 
                        
                    contentSetter={content_setter} 

                    site_date={site_date}
                
                />

            }

        case iselected_secondary_dashboard_type.Players:
            {

                return <PlayersSearchWidget 
                
                    contentSetter={content_setter} 
                    
                    currentContentID={current_content_id} 
                    
                    currentLeagueID={current_league_id} 

                    isMobile={false}
                    
                />

            }

        case iselected_secondary_dashboard_type.Teams:
            {

                return <TeamsSearchWidget 
                
                    contentSetter={content_setter}
                    
                    currentLeagueID={current_league_id}
                    
                />

            }
    }

}

function GetContentDashboard(
    
    content_dashboard_type: iselected_content_dashboard_type, 
    
    content_setter: any, 
    
    current_content_id: number, 
    
    current_league_id: number,

    site_date: string
    
) : JSX.Element
{

    switch(content_dashboard_type)
    {

        case iselected_content_dashboard_type.Fixture:
            {

                return <FixtureDashboard 

                    key={current_content_id}
                
                    fixture_id={current_content_id} 
                    
                    contentSetter={content_setter}

                    site_date={site_date}

                    isMobile={false}
                    
                />

            }

        case iselected_content_dashboard_type.Player:
            {

                return <PlayerDashboard 
                
                    player_id={current_content_id} 
                    
                    contentSetter={content_setter} 
                    
                    isMobile={false}

                />

            }

        case iselected_content_dashboard_type.Team:
            {

                return <TeamDashboard 
                
                    team_id={current_content_id} 
                    
                    contentSetter={content_setter} 
                    
                />

            }

        case iselected_content_dashboard_type.Standing:
            {

                return <LeagueStandingsFull 
                
                    isMobile={false} 
                    
                    league_id={current_content_id}
                    
                    contentSetter={content_setter} 
                    
                />

            }

        case iselected_content_dashboard_type.League:
            {

                return <LeagueDashboard 
                
                    league_id={current_content_id} 
                    
                    contentSetter={content_setter} 
                    
                />

            }

        case iselected_content_dashboard_type.HomeContent:
            {

                return <HomeDashboard 
                
                    contentSetter={content_setter} 
                    
                    league_id={current_content_id} 

                    site_date={site_date}
                    
                />

            }

         case iselected_content_dashboard_type.Insights:
            {

                return <InsightContentDashboard 
                
                    isMobile={false} 
                    
                    fixture_id={current_content_id} 

                    current_league_id={current_league_id}
                    
                    contentSetter={content_setter} 

                    site_date={site_date}
                    
                />

            }

        default:
            {

                return <HomeDashboard contentSetter={content_setter} isMobile={false} site_date={site_date} />

            }

    }

}

export function HandleDefaultMenuSwitching(dashboardType: iselected_secondary_dashboard_type, stateProps: any, callback: any | null = null) {

    stateProps.switchPrimaryDashboard(stateProps.primary_dashboard_state.selectedLeagueID);

    if(stateProps.secondary_dashboard_state.selectedDashboardType !== dashboardType) {

        stateProps.switchSecondaryDashboard(dashboardType);

    }

    switch(dashboardType)
    {
        case iselected_secondary_dashboard_type.ScoresAndFixtures: 
        case iselected_secondary_dashboard_type.Home: 
            {

                stateProps.switchContentDashboard(
                    
                    iselected_content_dashboard_type.HomeContent,
                    
                    null
                    
                );

            }
            break;
        case iselected_secondary_dashboard_type.Insights:
            {

                stateProps.switchContentDashboard(
                    
                    iselected_content_dashboard_type.Insights,
                    
                    null
                    
                );

            }
            break;
        // case iselected_secondary_dashboard_type.Standings: 
        //     {

        //         stateProps.switchContentDashboard(iselected_content_dashboard_type.Standing, null);

        //     }
        //     break;

    }

}