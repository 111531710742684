import { Chip, Typography } from '@mui/material';
import React, { useState } from 'react';
import SoccerLineUp from 'react-soccer-lineup';
import { GetGradientClass } from '../../../common/helpers/rating_helper';
import StatisticsModal from '../lineups/fixture_lineup_statistics_modal.js';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

function CreatePlayerArrayByPosition(squad, position, callback, color, textColor, homeTeam) {
    return squad
        .filter((player) => player.position_name === position)
        .map((player) => mapPlayer(player, callback, color, textColor, homeTeam));
}

function mapPlayer(player, callback, color, textColor, homeTeam) {
    return {
        homeTeam,
        numberColor: textColor,
        nameColor: textColor,
        color,
        playerId: player.player_id,
        name: player.player_name,
        number: player.jersey_number,
        onClick: callback,
        style: { numberColor: textColor, nameColor: textColor, color },
        cardStatus: player.card_status,
        totalGoals: player.total_goals || 0,
        totalAssists: null,
        rating: player.rating ? parseFloat(player.rating).toFixed(1) : 0,
        ratingClass: GetGradientClass(player.rating),
        playerImage: player.player_image
    };
}

function BuildFormation(formation, players, isHome, callback) {
    const formationNumbers = formation.split('-').map(Number);
    const formationRows = [];
    const goalkeeper = players[0]; 

    formationRows[0] = [mapPlayer(goalkeeper, callback, isHome ? '#000000' : '#FFFFFF', isHome ? '#FFFFFF' : '#000000', isHome)];

    let positionIndex = 1;
    for (let col = 0; col < formationNumbers.length; col++) {
        const positionalPlayers = [];
        for (let row = 0; row < formationNumbers[col]; row++) {
            const player = players[positionIndex];
            if (player) {
                positionalPlayers.push(
                    mapPlayer(player, callback, isHome ? '#000000' : '#FFFFFF', isHome ? '#FFFFFF' : '#000000', isHome)
                );
                positionIndex++;
            }
        }
        formationRows.push(positionalPlayers);
    }

    return formationRows;
}

const RatingCard = ({ logo, title, rating, gradientClass, formation }) => (
    <div style={{ padding: '10px', backgroundColor: '#a6bd98', border: '1px solid white' }}>
        <MDBRow>
            <MDBCol>
                <MDBRow className='no_side_padding' style={{ padding: '0 0 10px 0', alignItems: 'center' }}>
                    <MDBCol>
                        <Typography style={{ color: '#fbfbfb', fontSize: '14px', marginRight: '10px' }} variant='body1'>
                            {title}
                        </Typography>
                    </MDBCol>
                </MDBRow>
                <MDBRow className='no_side_padding' style={{ display: 'flex', alignItems: 'center' }}>
                    <MDBCol>
                        <img 
                            src={logo} 
                            alt="Team Logo" 
                            style={{ width: '40px', height: '40px', marginRight: '10px', position: 'relative', top: '-2px' }}
                        />
                        <span className={`${gradientClass} span_player_fixture_rating`} style={{ display: 'inline-block', color: 'white', width: '50px', textAlign: 'center', fontSize: '20px', fontWeight: '500' }}>
                            {rating == 0 ? "-" : rating?.toFixed(2) || "-"}
                        </span>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <MDBCol style={{  textAlign: 'right' }}>
                <MDBRow style={{ padding: '0 0 10px 0', alignItems: 'right' }}>
                    <Typography style={{ color: '#fbfbfb', fontSize: '14px', marginRight: '10px' }} variant='body1'>
                        Formation
                    </Typography>
                </MDBRow>
                <MDBRow style={{ display: 'flex', alignItems: 'right', }}>
                    <Typography style={{ fontWeight: '500', color: 'white', fontSize: '24px' }} variant='body1'>{formation}</Typography>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    </div>
);

const AverageRatingsDisplay = ({ home_team_logo, away_team_logo, average_home_team_rating, average_away_team_rating, home_team_formation, away_team_formation }) => (
    <MDBRow>
        <MDBCol style={{ padding: '10px' }}>
            <RatingCard 
                title="Average Rating" 
                rating={average_home_team_rating} 
                gradientClass={GetGradientClass(average_home_team_rating)}
                formation={home_team_formation} 
                logo={home_team_logo}
            />
        </MDBCol>
        <MDBCol style={{ padding: '10px' }}>
            <RatingCard 
                title="Average Rating" 
                rating={average_away_team_rating} 
                gradientClass={GetGradientClass(average_away_team_rating)} 
                formation={away_team_formation}
                logo={away_team_logo}
            />
        </MDBCol>
    </MDBRow>
);

export default function FixturePitch({ away_team_logo, home_team_logo, home_team_lineup, away_team_lineup, home_team_formation, away_team_formation, contentSetter, average_home_team_rating, average_away_team_rating }) {
    const [selectedPlayerStatistics, setSelectedPlayerStatistics] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => setModalOpen(false);

    function DisplayPlayerStatisticModal(ev) {
        const playerId = ev.target.closest('[playerid]')?.getAttribute('playerid');
        if (modalOpen || !playerId) return;

        const selectedPlayer = [...home_team_lineup, ...away_team_lineup].find((player) => player.player_id === playerId);

        if (selectedPlayer && selectedPlayer.fixture_player_statistics) {
            setSelectedPlayerStatistics(selectedPlayer.fixture_player_statistics);
            setModalOpen(true);
        }
    }

    const homeTeam = BuildFormation(home_team_formation, home_team_lineup, true, DisplayPlayerStatisticModal);
    const awayTeam = BuildFormation(away_team_formation, away_team_lineup, false, DisplayPlayerStatisticModal);

    return (
        <>
            <StatisticsModal playerStatistics={selectedPlayerStatistics} modalOpen={modalOpen} handleClose={handleClose} />
            <div style={{ backgroundColor: 'rgb(175, 206, 164)' }}>
                <SoccerLineUp
                    size="responsive"
                    color="#afcea4"
                    pattern="lines"
                    homeTeam={{ formationRow: homeTeam }}
                    awayTeam={{ formationRow: awayTeam }}
                />
                <AverageRatingsDisplay 
                    average_home_team_rating={average_home_team_rating}
                    average_away_team_rating={average_away_team_rating}
                    home_team_formation={home_team_formation}
                    away_team_formation={away_team_formation}
                    home_team_logo={home_team_logo}
                    away_team_logo={away_team_logo}
                />
                <Chip className="m-2" size="small" icon={<InfoOutlinedIcon />} label="Click on a player to view their in game statistics" />
            </div>
        </>
    );
}
