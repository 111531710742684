import { useEffect } from 'react';
import axios from 'axios';
import configuration from '../../config.json';

export const useStatPack = (fixture_id, setter) => {
    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {

          var result = { isLoading: false, data: null, fixture_id: fixture_id };

          const response = await axios.get(`${configuration.FRONT_END_API_v2}statpack/${fixture_id === null ? -1 : fixture_id}`);
          
          if (response.status === 200) {
            result.data = response.data;
          }

          setter(result);
        } 
        catch (error) {
          setter({ isLoading: false, data: null, fixture_id: fixture_id });
        }
      };
  
      fetchData();
  
      return () => {
        isMounted = false;
      };
    }, [fixture_id]);
  };