import { useCallback, useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { Paper, Typography } from "@mui/material";
import ErrorBoundary from "../../../application/error/error_boundary";
import { GetGradientClass } from "../../../common/helpers/rating_helper";

const PlayerItem = ({ isLast, player, placeholderImage, onPlayerClick }) => (
    <>
    <div className="clickable" onClick={() => onPlayerClick(player.id)} style={{ padding: '8px 15px 8px 8px', margin: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div style={{ position: 'relative', flex: '0 0 40px', marginRight: '16px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <img 
                    src={player.player_image || placeholderImage} 
                    alt={`${player.player_name} player profile image`} 
                    onError={(e) => { e.target.src = placeholderImage; }} 
                    style={{ height: '40px', width: '40px', borderRadius: '50%', border: '1px solid #a0a0a0' }}
                />
            </div>
            <div style={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                <div style={{textAlign: 'left', fontSiz: '16px'}}>
                    {player.player_name} 
                </div>
                {player.rating !== null && player.rating !== 0 && <div className={GetGradientClass(player.rating) + " player-rating"} style={{
                        marginLeft: 'auto',
                        padding: '5px',
                        fontSize: '16px',
                        borderRadius: '5px',
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                >
                    {player.rating} 
                </div>}
            </div>
        </div>
    </div>
    {!isLast && <div style={{borderBottom: '0.5px solid #dbdde9', width: '90%', marginLeft: 'auto', marginRight: 'auto' }}></div>}
    </>
);

const BuildPlayerList = ({ isHome, players, placeholderImage, onPlayerClick }) => (
    <div className="underlying_list_team_lineup" style={{ padding: 0, listStyleType: 'none' }}>
        {players.sort((a, b) => a.formation_position - b.formation_position).map((player, index) => (
            <PlayerItem isLast={(players.length - 1) === index} key={"lineup_" + player.id} player={player} placeholderImage={placeholderImage} onPlayerClick={onPlayerClick} /> 
        ))}
    </div>
);

const BuildLineupSection = ({ isHome, lineup, teamName, placeholderImage, onPlayerClick }) => (
    <MDBCol size="12" className="no_side_padding">
        <BuildPlayerList 
            isHome={isHome}
            players={lineup}
            placeholderImage={placeholderImage}
            onPlayerClick={onPlayerClick}
        />
    </MDBCol>
);

export default function MobileFixtureLineups({ fixture_id, fixtureLineups, teams, contentSetter }) {
    
    const displayPlayerStats = useCallback((playerId) => {
        // Handle player stats display logic
    }, []);

    const [IsHome, setIsHome] = useState(true);

    var home_lineup = fixtureLineups.data.home_team_lineup
        .filter(player => player.type_name === 'Lineup')
        .sort((a, b) => a.formation_position - b.formation_position);

    var away_lineup = fixtureLineups.data.away_team_lineup
        .filter(player => player.type_name === 'Lineup')
        .sort((a, b) => a.formation_position - b.formation_position);

    var home_bench = fixtureLineups.data.home_team_lineup
        .filter(player => player.type_name === 'Bench')
        .sort((a, b) => a.formation_position - b.formation_position);

    var away_bench = fixtureLineups.data.away_team_lineup
        .filter(player => player.type_name === 'Bench')
        .sort((a, b) => a.formation_position - b.formation_position);

    var isPredicted = [...home_lineup, ...away_lineup]
        .filter(player => 
            player.formation_field != null && 
            player.formation_position == null).length > 0;
        
    const placeholderImage = 'https://cdn.sportmonks.com/images/soccer/placeholder.png';

    return (
        <ErrorBoundary>
            <>
            <Paper elevation={1} className="mb-2 widget_fade_in">
                <MDBRow>
                <MDBCol className="pt-3 pb-3" size="12">
                        <div style={{display: 'flex'}}>
                            <div style={{
                                    width: '50%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    backgroundColor: IsHome ? '#1976d21f' : '#0000000a',
                                    borderRadius: '9px 0px 0px 9px',
                                    height: '40px',
                                    alignItems: 'center'
                                }}
                                onClick={() => setIsHome(true)}
                            >
                                <img style={{width: '28px', height: '28px'}} src={teams.home_team.logo}alt={teams.home_team.name + " team logo"} />
                            </div>
                            <div style={{
                                    width: '50%', 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    backgroundColor: IsHome ? '#0000000a' : '#1976d21f',
                                    borderRadius: '0px 9px 9px 0px',
                                    height: '40px',
                                    alignItems: 'center'
                                }}
                                onClick={() => setIsHome(false)}
                            >
                                <img style={{width: '28px', height: '28px'}} src={teams.away_team.logo} alt={teams.away_team.name + " team logo"} />
                            </div>
                        </div>
                    </MDBCol>
                </MDBRow>
            </Paper>
            <Paper elevation={1} className="mb-2 widget_fade_in">
                <MDBRow className="row_lineup_tab no_side_padding">
                    <MDBCol size='12'>
                        <label style={{ width: '100%', textAlign: 'left', fontSize: '16px', textAlign: 'center', fontWeight: '700' }}>{isPredicted ? "Predicted Lineups" : "Starting Lineup"}</label>
                    </MDBCol>
                    <MDBCol size="12" className="no_side_padding">
                        <BuildLineupSection 
                            isHome={true}
                            lineup={IsHome ? home_lineup : away_lineup}
                            teamName={IsHome ? teams.home_team.name : teams.away_team.name}
                            placeholderImage={placeholderImage}
                            onPlayerClick={displayPlayerStats}
                        />
                    </MDBCol>
                </MDBRow>
            </Paper>

            {(home_bench.length > 0 || away_bench.length > 0) && <Paper elevation={1} className="widget_fade_in">
                <MDBRow className="row_lineup_tab no_side_padding">
                    <MDBCol size='12'>
                        <label style={{ width: '100%', textAlign: 'left', fontSize: '16px',  textAlign: 'center', fontWeight: '700' }}>Bench</label>
                    </MDBCol>
                    <MDBCol size="12" className="no_side_padding">
                        <BuildLineupSection 
                            lineup={IsHome ? home_bench : away_bench}
                            teamName={IsHome ? teams.home_team.name : teams.away_team.name}
                            placeholderImage={placeholderImage}
                            onPlayerClick={displayPlayerStats}
                        />
                    </MDBCol>
                </MDBRow>
            </Paper>}
            </>
        </ErrorBoundary>
    );
}
